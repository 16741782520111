import React from "react";
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { URLS } from "../_config";
import { GrHomeRounded } from 'react-icons/gr';
import { RiFolderChartLine, RiNotification2Line } from 'react-icons/ri'
import { BsChevronLeft, BsPerson } from 'react-icons/bs'
import { FiCompass } from 'react-icons/fi'
import '../styles/components/header.scss';



type BackMode = {
	pageName: string;
	backUrl: string;
}

interface incomingProps {
	backMode?: BackMode,
	active?: any,
}


export default function Header({ backMode, active}: incomingProps) {
	const userApi = useUserApi();
	const user: any = useUserSelector()
	const navigate = useNavigate();


	const logout = (e: any) => {
		console.log("logout")
		e.preventDefault();
		e.stopPropagation();
		userApi.logout({}, (message: string, resp: any) => {
			navigate('/')
		}, (message: string) => {

		})
	}

	const updateProfile = () => {
		navigate(URLS.PROFILE)
	}


	return (

		<React.Fragment>
			{
				backMode ?
					<div className="navbar-header back-mode">
						<Link to={backMode.backUrl} className="icon back"><BsChevronLeft /></Link>
						<p className="label">{backMode.pageName}</p>
						{user ?
							<Dropdown>
								<Dropdown.Toggle className="btn-toggle btn-toggle-svg" id="dropdown-basic">

									<BsPerson />
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item onClick={updateProfile}>View Profile</Dropdown.Item>
									<Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							:
							<Link to="/welcome" className="icon"><BsPerson /></Link>
						}
					</div>
					:
					<div className="navbar-header">
						<Link to={'/'} className="logo">
							<img src="/assets/img/logo.svg" alt="Dirco" />
							<p>International Relations &<br />Cooperation</p>
						</Link>
						<div className="nav-links">
							<Link to="/" className={active == "home" ? "nav-link active" : "nav-link"}>
								<GrHomeRounded />
								<p>Home</p>
							</Link>
							<Link to="/advisory" className={active == "advisory" ? "nav-link active" : "nav-link"}>
								<RiNotification2Line />
								<p>Alerts</p>
							</Link>
							<Link to="" className={"nav-link"}>
								<FiCompass />
								<p >Consular Services</p>
							</Link>
							<Link to="/cases" className={active == "cases" ? "nav-link active" : "nav-link"}>
								{/* <RiFolderChartLine /> */}
								<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path opacity=".4" d="m6.178 13.504 2.993-3.89 3.415 2.681 2.929-3.78" stroke="#8181A5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<circle cx="18.929" cy="2.922" r="1.922" stroke="#8181A5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M13.858 1.842H6.59c-3.011 0-4.879 2.133-4.879 5.144v8.083c0 3.011 1.831 5.135 4.88 5.135h8.603c3.012 0 4.879-2.124 4.879-5.135v-7.04" stroke="#8181A5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
									<p>Cases</p>
							</Link>
							{user ?
								<Dropdown>
									<Dropdown.Toggle className={"btn-toggle"} id="dropdown-basic">
										<BsPerson />
										<p>Profile</p>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item onClick={updateProfile}>View Profile</Dropdown.Item>
										<Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>

								:
								<Link to="/welcome" className={"icon"}><BsPerson /></Link>
							}
						</div>
					</div>
			}


		</React.Fragment>

	);
}
