import { call } from 'redux-saga/effects';
import { API_URL } from '../../_config'
import { CallApi } from './api/callApi';



export function* getCases(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.CASES.GET_CASES, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCasesDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.CASES.CASE_DETAILS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCasesTypes(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.CASES.GET_CASES_TYPES, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createCase(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.CASES.CREATE_CASE, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            sessionStorage.setItem("CaseId", JSON.stringify(resp.data.CaseId));
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createCaseStepTwo(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.CASES.CREATE_CASE_STEP2, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createCaseDocUpload(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.CASES.CREATE_CASE_DOC_UPLOAD, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
