import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


export default function Step0() {
    return (
        <React.Fragment>
            <h1>Travels</h1>
            <p>Please select an option to add your travel.</p>
            <Link to={'/new-travel?step=1'}><Button className="btn" variant="success" type='button'>Complete form</Button></Link>
        </React.Fragment>
    )
}