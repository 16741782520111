import React, { useState } from "react";
import { Card, Button, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/components/cases/caseDetails.scss";

interface incomingProps {
    data: any
}

export default function Case({ data }: incomingProps) {

    return (
        <div className="case-details">
            <div className="case-details-inner">
                <p className="case-info">Your case has been marked as {data?.Status}</p>
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <div className="case-avatar">
                                </div>
                                <div className="card-title">{data?.CaseNumber}</div>
                            </div>
                            <div className="more-btn"><img src="/assets/img/icon.svg" alt="details" /></div>
                        </div>
                    </Card.Header>
                    {data?.Attachments && data?.Attachments.length > 0 ?
                        <Carousel className="details-carousal">
                            {data?.Attachments.map((item: any, i: number) => (
                                <Carousel.Item key={i}>
                                    <Card.Img variant="top" src={item} alt="" />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        : null
                    }
                    <Card.Body>
                        <Card.Title className="case-title">
                            <h3>{data?.CaseType}</h3>
                            <h4>{data?.City}, {data?.Country}</h4>
                            <h4>{data?.CreatedOn}</h4>
                        </Card.Title>
                        <Card.Text>
                            {data?.Description}
                        </Card.Text>
                        <div className="text-end">
                            <Link to={`/cases`}><Button variant="primary">Ok</Button></Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}