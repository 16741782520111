import React from 'react'
import Header from '../components/header'
import { Container } from 'react-bootstrap';
import { BsBell } from "react-icons/bs";
import '../styles/countrydirectory.scss';
import { usePopularCountriesSelector } from '../_common/hooks/selectors/countryDirectorySelector';
import { Link, useNavigate } from 'react-router-dom';

const PopularCountries = () => {

    const popularCountries = usePopularCountriesSelector()
    const navigate = useNavigate()

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {
            return 'data:image/jpeg;base64,' + url
        }
    }

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="country-directory-wrapper">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Popular Countries</h2>
                        <Link className="btn btn-success my-2" to="/country-directory">Back</Link>
                    </div>
                    <div className="coutry-city-block-wrapper coutry-city-block-wrapper-single">

                        {popularCountries && popularCountries.length > 0 ?
                            popularCountries.map((country: any, index: number) => (
                                <div className="coutry-city-block" key={index} onClick={() => navigate(`/country-info/${country?.CountryId}`)}>
                                    <div className="coutry-city-block-pic">
                                        <img src={country?.CountryImage && checkImageBase64Code(country?.CountryImage)} alt="img" />
                                    </div>
                                    <div className="coutry-city-block-content">
                                        <h4>{country?.Name}</h4>
                                        <h5 className="alert-text"><span><BsBell /></span>{country?.NoOfAlerts}+ Active Alerts</h5>
                                    </div>
                                </div>
                            ))
                            :
                            <h6 className="alert alert-danger text-center w-100" role="alert">No Data</h6>
                        }
                    </div>
                </div>
                
            </Container>
        </React.Fragment>
    )
}

export default PopularCountries