import React from 'react';
import { Accordion, Carousel, Container } from 'react-bootstrap';
import Header from '../components/header';
import '../styles/travelcity.scss';
import { useCityAlertSelector } from '../_common/hooks/selectors/cityAlertSelector';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import LeftPanel from '../components/leftPanel';

const TravelCityInfo = () => {

    const cityAlert: any = useCityAlertSelector() || {};
    console.log({ cityAlert })

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="wrapper-left-right-panel">
                    <LeftPanel img={"/assets/img/SideSlide-2.jpg"} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."} />
                    <div className="dashboard">
                        <Container className="dashboard-container">
                            <div className="main-div travelcity-listinfo">
                                <h2>{cityAlert?.Name}</h2>
                                <div className="city-country-scroll-view">
                                    <div className="cityinfo_image">
                                        {cityAlert?.Images && cityAlert?.Images.length > 0 ?
                                            <Carousel className="details-carousal">
                                                {cityAlert?.Images.map((item: any, i: number) => (
                                                    <Carousel.Item key={i}>
                                                        <img
                                                            className="d-block w-100"
                                                            src={item}
                                                            alt="First slide"
                                                        />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                            : null
                                        }
                                    </div>
                                    <Accordion defaultActiveKey={['0', '1', '2', '3']}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg></span>General Information</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="accordian_listtxt">
                                                    <ul>
                                                        <li><b>Country name: </b><span>{cityAlert?.Name}</span></li>
                                                        <li><b>Police station: </b><span>{cityAlert?.PoliceStation}</span></li>
                                                        <li><b>Head of mission: </b><span>{cityAlert?.HeadOfMission}</span></li>
                                                    </ul>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg></span>Crime News</Accordion.Header>
                                            <Accordion.Body>
                                                {/* <div className="accordian_listtxt"></div> */}
                                                {cityAlert && cityAlert?.CrimeNews ? parse(cityAlert?.CrimeNews) : null}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg></span>Cultural Information</Accordion.Header>
                                            <Accordion.Body>
                                                {cityAlert && cityAlert?.CulterInformation ? parse(cityAlert?.CulterInformation) : null}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg></span>Do's And Don't</Accordion.Header>
                                            <Accordion.Body>
                                                {cityAlert && cityAlert?.DoAndDont ? parse(cityAlert?.DoAndDont) : null}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <Link className="btn btn-success mt-2" to="/travels-city">Back</Link>
                            </div>
                        </Container>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default TravelCityInfo