import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useTravelsApi } from '../../_common/hooks/api/travelsApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import StepIndicators from './stepIndicators'

interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

export default function Step1({ onSubmitAddressForm, tab }: incomingProps) {

    const { register, handleSubmit, formState: { errors }, control } = useForm()

    const commonApi = useCommonApi()
    const travelApi = useTravelsApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()

    const [travelReason, setTravelReason] = useState<any>(null)
    const [travelId, setTravelId] = useState<any>(null)
    const [subscribeToggle, setSubscribeToggle] = useState<boolean>(true)
    const [locationToggle, setLocationToggle] = useState<boolean>(false)
    const [longitude, setLongitude] = useState<any>(null)
    const [latitude, setLatitude] = useState<any>(null)

    let TravelId: any = sessionStorage.getItem('TravelId')

    const retrieveTravelReason = () => {
        commonApi.retrieveTravelReason({ "DropdownName": "xql_travelreason" },
            (message: string, resp: any) => {
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setTravelReason(options)
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const onSubmit = (data: any) => {
        showLoader()
        let params = {
            "EntityName": "TravelReason",
            "TravelId": travelId,
            "TravelReason": data.TravelReason.value
        }
        showLoader();
        travelApi.createTravelStepFour(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message)
            navigate('/travels-city')
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    useEffect(() => {
        retrieveTravelReason()
    }, [])

    useEffect(() => {
        if (TravelId == 'undefined') {
            setTravelId(null)
        }else {
            setTravelId(JSON.parse(TravelId))
        }
    }, [TravelId])

    useEffect(() => {
        if(locationToggle){
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitude(position.coords.latitude)
                setLongitude(position.coords.longitude)
            });
        }

    },[locationToggle])

    return (
        <React.Fragment>
            <h1>Register new travel</h1>
            <StepIndicators step={3} />
            <div className="d-flex justify-content-between mt-5">
                <h2>What is the primary reason for your travel?</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                <div className="container-fluid p-0">
                    <Row>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.TravelReason ? ' invalid' : '')}>
                                <legend>Reason for travel</legend>
                                <Controller
                                name={"TravelReason"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => {
                                    return (<Select
                                        className={"select-box"}
                                        options={travelReason ? travelReason : []}
                                        placeholder={""}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />)
                                }}
                            />
                            </fieldset>
                        </Col>
                    </Row>
                </div>

                <div className="d-flex justify-content-between">
                <h3>Subscribe to alert</h3>
                <div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => setSubscribeToggle(!subscribeToggle)} defaultChecked={subscribeToggle}></input>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <h3>Use my Current location</h3>
                <div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => setLocationToggle(!locationToggle)} defaultChecked={locationToggle}></input>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                    </div>
                </div>
            </div>
                <div className="mt-4 d-flex gap-2 login-btns">
                    <Button className="btn" variant="success" type='submit'>Save & continue</Button>
                </div>
            
            </form>
        </React.Fragment>
    )
}