import { call } from 'redux-saga/effects';
import { API_URL } from '../../_config'
import { CallApi } from './api/callApi';


export function* createAlert(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADVISORY.CREATE_ALERT, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getContactAlert(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.ADVISORY.GET_CONTACT_ALERT, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrieveAlertType(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADVISORY.RETRIEVE_ALERT_TYPE, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAlertByType(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.ADVISORY.GET_ALERT_BY_TYPE, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}