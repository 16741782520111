import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import '../styles/profile.scss';
import { BsCalendar4Event, BsPerson } from "react-icons/bs";
import { MdMobileFriendly, MdPlayArrow } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function ProfileSetting() {

    const navigate = useNavigate()

    return (
        <React.Fragment>
            <Header/>
            <Container>
                <div className="profile-container">
                    <div className="profile-block-header">
                        <div className="d-flex gap-3">
                            <BsPerson />
                            <div className="profile-block-content">
                                <h3>Personal Details</h3>
                                <h4>Edit your personal information</h4>
                            </div>
                        </div>
                        <div className="arrow align-self-center" style={{ cursor: 'pointer' }}>
                            <MdPlayArrow onClick={() => navigate(`/profile-details`)} />
                        </div>
                    </div>
                    <div className="profile-block-header">
                        <div className="d-flex gap-3">
                            <MdMobileFriendly />
                            <div className="profile-block-content">
                                <h3>Next of kin</h3>
                                <h4>See and edit your next of kin</h4>
                            </div>
                        </div>
                        <div className="arrow align-self-center" style={{ cursor: 'pointer' }}>
                            <MdPlayArrow onClick={() => navigate(`/kin-details`)} />
                        </div>
                    </div>
                    <div className="profile-block-header">
                        <div className="d-flex gap-3">
                            <BsCalendar4Event />
                            <div className="profile-block-content">
                                <h3>Location settings</h3>
                                <h4>Manage your location settings</h4>
                            </div>
                        </div>
                        <div className="arrow align-self-center" style={{ cursor: 'pointer' }}>
                            <MdPlayArrow onClick={() => navigate(`/location-settings`)} />
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}