import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Header from '../components/header'
import { useUserApi } from '../_common/hooks/api/UserApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import LeftPanel from "../components/leftPanel";

const PrivacyPolicy = () => {

    const userApi = useUserApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [privacyStatement, setPrivacyStatement] = useState<any>(null)

    const getprivacyPolicy = () => {
        showLoader()
        userApi.getprivacyPolicy({},
            (message: string, resp: any) => {
                hideLoader()
                console.log({ resp })
                setPrivacyStatement(resp[0]["xql_privacypolicy"])
            }, (message: string) => {
                hideLoader()
                // console.log(message);
            })
    }

    useEffect(() => {
        getprivacyPolicy()
    }, [])


    return (
        <React.Fragment>
            <Header backMode={{ pageName: "Sign up", backUrl: "/welcome" }} />
            <Container>
                <div className="wrapper-left-right-panel">
                    <LeftPanel img={"/assets/img/SideSlide-2.jpg"} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."}/>
                    <div className="card-outer signup-wrapper-outer">
                        <div className="card privacy-card">
                            <h4>Privacy Policy</h4>
                            <p>
                                {privacyStatement ? privacyStatement : null}
                            </p>

                            <div className="d-flex align-items-start">
                                <input type="checkbox" className="me-2 mt-2 align-items-start" id='privacy'/>
                                <label htmlFor="privacy">Do you have travel Insurance? Travellers are encouraged to have Insurance</label>
                            </div>

                            <div className="mt-4 w-100 login-btns d-flex gap-2">
                                <Link to="/signup" className="btn btn-success w-100">Accept</Link>
                                <Link to="/welcome" className="btn w-100" >Decline</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default PrivacyPolicy