import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import '../styles/profile.scss';
import { BsCalendar4Event, BsChevronRight, BsPerson } from "react-icons/bs";
import { MdMobileFriendly, MdPlayArrow } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import QRCode from "react-qr-code";

export default function Profile() {

    const navigate = useNavigate()
    const user: any = useUserSelector()

    return (
        <React.Fragment>
            <Header/>
            <Container>
                <div className="profile-container">
                    <div className="profile-block-header" onClick={() => navigate(`/profile-settings`)} style={{ cursor: 'pointer' }}>
                        <div className="d-flex gap-3">
                            <BsPerson />
                            <div className="profile-block-content">
                                <h6>{user?.fullname}</h6>
                                <h3>Profile settings</h3>
                            </div>
                        </div>
                        <div className="arrow">
                            <BsChevronRight />
                        </div>
                    </div>
                    <div className="profile-block-body-content">
                        <p>Your personal QR code - Connect with family</p>
                        <div className="qr-code">
                            {/* <img src="assets/img/qr-code.jpg" alt="" /> */}
                            <QRCode value={user?.contactid} />
                        </div>
                    </div>
                    <div className="profile-block-header">
                        <div className="d-flex gap-3">
                            <MdMobileFriendly />
                            <div className="profile-block-content">
                                <h3>Notifications</h3>
                                <h4>Recent notifications from CICC</h4>
                            </div>
                        </div>
                        <div className="arrow align-self-center">
                            <MdPlayArrow onClick={() => navigate(`/notifications`)} />
                        </div>
                    </div>
                    <div className="profile-block-header">
                        <div className="d-flex gap-3">
                            <BsCalendar4Event />
                            <div className="profile-block-content">
                                <h3>Upcoming travels</h3>
                                <h4>See all your scheduled travels</h4>
                            </div>
                        </div>
                        <div className="arrow align-self-center">
                            <MdPlayArrow onClick={() => navigate(`/travels`)} />
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}