import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import StepIndicators from './stepIndicators';
import Select from 'react-select';
import { useTravelsApi } from '../../_common/hooks/api/travelsApiHook';
import toast from 'react-hot-toast';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import moment from 'moment';

interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

export default function Step1({ onSubmitAddressForm, tab }: incomingProps) {

    const { register, handleSubmit, formState: { errors }, watch, control } = useForm()
    const country = watch('CountryId');
    const depDate = watch('DepartureDate');

    const commonApi = useCommonApi()
    const travelApi = useTravelsApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()
    const user: any = useUserSelector()

    const [countries, setCountries] = useState<any>(null)
    const [cities, setCities] = useState<any>(null)
    const [lodgeType, setLodgeType] = useState<any>(null)

    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCountries(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_countryid;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getCities = (id: any = country?.value) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.getCities({
                CountryId: id
            }, (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCities(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_cityid;
                        return data;
                    }))
                }
                setTimeout(() => {
                    resolve()
                }, 300)
            }, (message: string) => {
                // console.log(message);
                reject()
            });
        })
    }

    const retrieveLodgeType = () => {
        commonApi.retrieveLodgeType({ "DropdownName": "xql_lodgetype" },
            (message: string, resp: any) => {
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setLodgeType(options)
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const onSubmit = (data: any) => {

        if (user) {
            let params = {
                "EntityName": "Travel",
                "CountryId": data.CountryId.value,
                "CityId": data.CityId.value,
                "DepartureDate": data.DepartureDate.split("-").join("/"),
                "ReturnDate": data.ReturnDate.split("-").join("/"),
                "LodgeType": data.LodgeType.value,
                "LodgeName": data.LodgeName,
                "Address": data.Address,
                "ContactId": user?.contactid
            }
            showLoader();
            travelApi.createTravelStepOne(params, (message: string, resp: any) => {
                console.log({ resp })
                hideLoader();
                toast.success(message)
                sessionStorage.setItem('CityId', JSON.stringify(data.CityId.value))
                navigate('/new-travel?step=2')
            }, (message: string) => {
                toast.error(message)
                hideLoader();
            });
        }
    }

    useEffect(() => {
        if (country) {
            getCities()
        }
    }, [country])

    useEffect(() => {
        getCountries()
        retrieveLodgeType()
    }, [])

    return (
        <React.Fragment>
            <h1>Register new travel</h1>
            <StepIndicators step={1} />
            <div className="d-flex justify-content-between mt-5">
                <h2>Where and when are you travelling?</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                <Row>
                    <Col sm={6}>
                        <fieldset className={"input" + (errors?.CountryId ? ' invalid' : '')}>
                            <legend>Country</legend>
                            <Controller
                                name={"CountryId"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => {
                                    return (<Select
                                        className={"select-box"}
                                        options={countries ? countries : []}
                                        placeholder={""}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />)
                                }}
                            />
                        </fieldset>
                    </Col>
                    <Col sm={6}>
                        <fieldset className={"input" + (errors?.CityId ? ' invalid' : '')}>
                            <legend>City</legend>
                            <Controller
                                name={"CityId"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => {
                                    return (<Select
                                        className={"select-box"}
                                        options={cities ? cities : []}
                                        placeholder={""}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />)
                                }}
                            />
                        </fieldset>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <fieldset className={"input" + (errors?.DepartureDate ? ' invalid' : '')}>
                            <legend>Departure date</legend>
                            <input type="date" className="" placeholder='' {...register("DepartureDate", { required: true, maxLength: 80 })} min={moment(Date.now()).format("YYYY-MM-DD")} />
                        </fieldset>
                    </Col>
                    <Col sm={6}>
                        <fieldset className={"input" + (errors?.ReturnDate ? ' invalid' : '')}>
                            <legend>Return date</legend>
                            <input type="date" className="" placeholder='' {...register("ReturnDate", { required: false, maxLength: 80 })} min={depDate} />
                        </fieldset>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <fieldset className={"input" + (errors?.LodgeType ? ' invalid' : '')}>
                            <legend>Where are you staying?</legend>
                            {/* <input type="text" className="" placeholder='Thailand' {...register("LodgeType", { required: true, maxLength: 80 })} /> */}
                            <Controller
                                name={"LodgeType"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => {
                                    return (<Select
                                        className={"select-box"}
                                        options={lodgeType ? lodgeType : []}
                                        placeholder={""}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />)
                                }}
                            />
                        </fieldset>
                    </Col>
                    <Col sm={6}>
                        <fieldset className={"input" + (errors?.LodgeName ? ' invalid' : '')}>
                            <legend>Name of Residence</legend>
                            <input type="text" className="" {...register("LodgeName", { required: true, maxLength: 80 })} />
                        </fieldset>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <fieldset className={"input" + (errors?.Address ? ' invalid' : '')}>
                            <legend>Address</legend>
                            <textarea className="" {...register("Address", { required: true, maxLength: 80 })} />
                        </fieldset>
                    </Col>
                </Row>
                <div className="mt-4 d-flex gap-2 login-btns">
                    <Button className="btn" variant="success" type='submit'>Save & continue</Button>
                </div>
            </form>
        </React.Fragment>
    )
}