import React, { useState } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import '../styles/login.scss';
import { toast } from "react-hot-toast";

export default function Home() {
	const navigate = useNavigate();
	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const [loginError, setLoginError] = useState<string | null>(null);
	const { register, handleSubmit, formState: { errors } } = useForm();

	const onSubmit = (data: any) => {
		let params: any = {
			"Email": data.email,
			"Password": data.password
		}
		showLoader();
		userApi.login(params, (message: string, resp: any) => {
			console.log({ resp })
			hideLoader();
			// sessionStorage.setItem('userDetails', JSON.stringify(resp.data.outputParameters.RequestResults))
		}, (message: string) => { // login error..
			toast.error(message)
			setLoginError(message);
			hideLoader();
		});
	}

	return (
		<React.Fragment>
			<Container className="login-container">
				<div className="card">

					<div className="d-flex gap-3 align-items-center">
						<div className="logo">
							<img src="../assets/img/logo.svg" alt="" />
						</div>
						<div>
							<h1>International relations & cooperation</h1>
							<p className="m-0">Department:<br></br>
								International Relations and Cooperation
								REPUBLIC OF SOUTH AFRICA</p>
						</div>
					</div>

					<div className="text-center w-100">
						<h2 className="mb-5">Welcome to DIRCO</h2>
						<h3>Enter your details to proceed further</h3>
					</div>

					<form onSubmit={handleSubmit(onSubmit)}>

						<fieldset className={"input" + (errors?.email ? ' invalid' : '')}>
							<legend>Email</legend>
							<input type="text" placeholder="Email" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}></input>
						</fieldset>
						{errors?.email ? <div className="invalid text-danger">
							{errors.email.type === "required" ? 'Required' : null}
							{errors.email.type === "pattern" ? 'Invalid email address' : null}
						</div> : null}

						<fieldset className={"input" + (errors?.password ? ' invalid' : '')}>
							<legend>Password</legend>
							<input type="password" placeholder="Password" {...register("password", { required: true, maxLength: 80 })} />
						</fieldset>
						{errors?.password ? <div className="invalid text-danger">Required</div> : null}
						{loginError ? <div className="invalid text-danger">{loginError}</div> : null}




						<Row className="mb-2">
							<Col md={12}>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" {...register("remember", {})} />
									<label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
										Remember me
									</label>
								</div>
							</Col>
							<Col md={12} className="small">
								<Link to="/forgot-password">Forgot Password?</Link> | <Link to="/welcome">New User?</Link>
							</Col>
						</Row>
						<div className="mobbottom_fixbtn">
							<Button variant="success" className="w-50 mt-2" type="submit">Login</Button>
						</div>
					</form>

				</div>
			</Container>
		</React.Fragment>
	);


}