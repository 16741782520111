import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import '../styles/notification.scss';

export default function Notifications() {

    return (
        <React.Fragment>
            <Header backMode={{ pageName: "Notifications", backUrl: "/profile" }} />
            <Container>
                <div className="notification-wrapper">
                    <div className="notification-row">
                        <div className="notification-content d-flex align-items-center">
                            <div className="notification-avatar">A</div>
                            <div>
                                <h3>Non-critical Advisory</h3>
                                <h4>Phuket, Thailand</h4>
                            </div>
                        </div>
                        <div className="notification-pic">
                            <img src="/assets/img/attachment-pic-5.jpg" alt="" />
                        </div>
                    </div>

                    <div className="notification-row">
                        <div className="notification-content d-flex align-items-center">
                            <div className="notification-avatar">A</div>
                            <div>
                                <h3>Non-critical Advisory</h3>
                                <h4>Phuket, Thailand</h4>
                            </div>
                        </div>
                        <div className="notification-pic">
                            <img src="/assets/img/attachment-pic-5.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}