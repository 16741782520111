import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useTravelsApi } from '../../_common/hooks/api/travelsApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import StepIndicators from './stepIndicators';
import * as CountryList from 'country-list-with-dial-code-and-flag';
import { handleKeyPressNumber } from '../../_common/functions';

interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

export default function Step1({ onSubmitAddressForm, tab }: incomingProps) {

    const { register, handleSubmit, formState: { errors }, watch, control } = useForm()
    const country = watch('CountryId');

    const commonApi = useCommonApi()
    const travelApi = useTravelsApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()
    const user: any = useUserSelector()

    const [countries, setCountries] = useState<any>(null)
    const [cities, setCities] = useState<any>(null)
    const [dialCode, setDialCode] = useState<any>(null)
    const [relationship, setRelationship] = useState<any>(null)
    const [groupToggle, setGroupToggle] = useState<boolean>(false)
    const [aloneToggle, setAloneToggle] = useState<boolean>(true)
    const [childrenToggle, setChildrenToggle] = useState<boolean>(false)
    const [kinToggle, setKinToggle] = useState<boolean>(true)
    const [travelId, setTravelId] = useState<any>(null)

    let TravelId: any = sessionStorage.getItem('TravelId')

    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCountries(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_countryid;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getCities = (id: any = country?.value) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.getCities({
                CountryId: id
            }, (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCities(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_cityid;
                        return data;
                    }))
                }
                setTimeout(() => {
                    resolve()
                }, 300)
            }, (message: string) => {
                // console.log(message);
                reject()
            });
        })
    }

    const getDialCode = (data: any) => {
        if (data.length > 0) {
            setDialCode(data.map((item: any) => {
                item.label = item.dial_code;
                item.value = item.dial_code;
                return item;
            }))
        }
    }

    const dialCodeOptionUI = (props: any) => {
        const { innerProps, innerRef, data } = props;
        return (
            <div ref={innerRef} {...innerProps} className="dial-code-option">
                {data.flag}
                <span>{data.dial_code}</span>
            </div>
        );
    }

    const retrieveRelationShip = () => {
        commonApi.retrieveRelationShip({ "DropdownName": "xql_relationshiotype" },
            (message: string, resp: any) => {
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setRelationship(options)
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const onSubmit = (data?: any) => {
        if (user) {
            let params = {
                "EntityName": "NextOfKin",
                "ContactId": user?.contactid,
                "FirstName": data.FirstName,
                "LastName": data.LastName,
                "Email": data.Email,
                "DialCode": data.DialCode.value,
                "CellPhone": data.CellPhone,
                "CountryId": data.CountryId.value,
                "CityId": data.CityId.value,
                "RelationshipType": data.RelationshipType.value,
                "Address": data.Address,
                "NextOfKinId": ""

            }
            showLoader();
            travelApi.createTravelStepTwo(params, (message: string, resp: any) => {
                console.log({ resp })
                hideLoader();
                submitStepThree(resp.NextOfKinId)
                // navigate('/new-travel?step=3')
            }, (message: string) => {
                toast.error(message)
                hideLoader();
            });
        }
    }

    const submitStepThree = (nextOfKinId: any = "") => {
        console.log(travelId)
        let params = {
            "EntityName": "TravelNextOfKin",
            "TravelId": travelId,
            "TravellingInGroup": groupToggle,
            "TravellingAlone": aloneToggle,
            "TravellingWithChildren": childrenToggle,
            "InCountryNextofKin": kinToggle,
            "NextOfKinId": nextOfKinId
        }
        showLoader();
        travelApi.createTravelStepThree(params, (message: string, resp: any) => {
            console.log({ resp })
            hideLoader();
            navigate('/new-travel?step=3')
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    useEffect(() => {
        if (country) {
            getCities()
        }
    }, [country])

    useEffect(() => {
        getCountries()
        retrieveRelationShip()
        getDialCode(CountryList.getList())
    }, [])

    useEffect(() => {
        if (TravelId == 'undefined') {
            setTravelId(null)
        } else {
            setTravelId(JSON.parse(TravelId))
        }
    }, [TravelId])


    return (
        <React.Fragment>
            <h1>Register new travel</h1>
            <StepIndicators step={2} />
            <div className="d-flex justify-content-between mt-5">
                <h2>Where and when are you travelling?</h2>
            </div>
            <div className="d-flex justify-content-between">
                <h3>Are you travelling alone?</h3>
                <div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => { setAloneToggle(!aloneToggle); setGroupToggle(false); setChildrenToggle(false); }} defaultChecked={aloneToggle}></input>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                    </div>
                </div>
            </div>
            <div className={"d-flex justify-content-between" + (aloneToggle ? ' disabled-area' : '')}>
                <h3>Are you travelling with family?</h3>
                <div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedTwo" onChange={() => setGroupToggle(!groupToggle)} checked={groupToggle}></input>
                        <label className="form-check-label" htmlFor="flexSwitchCheckCheckedTwo"></label>
                    </div>
                </div>
            </div>
            <div className={"d-flex justify-content-between" + (aloneToggle ? ' disabled-area' : '')}>
                <h3>Do you have small children travelling with you (under the age of  12 years )</h3>
                <div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedThree" onChange={() => setChildrenToggle(!childrenToggle)} checked={childrenToggle}></input>
                        <label className="form-check-label" htmlFor="flexSwitchCheckCheckedThree"></label>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <h3>Add in-country next of kin</h3>
                <div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedFour" onChange={() => setKinToggle(!kinToggle)} defaultChecked={kinToggle}></input>
                        <label className="form-check-label" htmlFor="flexSwitchCheckCheckedFour"></label>
                    </div>
                </div>
            </div>
            {kinToggle ?
                <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                    <div>
                        <h2>Contact details of next of kin</h2>
                    </div>
                    <div className="container-fluid p-0">
                        <Row>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.FirstName ? ' invalid' : '')}>
                                    <legend>First name</legend>
                                    <input type="text" className="" {...register("FirstName", { required: true, maxLength: 80 })}></input>
                                </fieldset>
                            </Col>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.LastName ? ' invalid' : '')}>
                                    <legend>Last name</legend>
                                    <input type="text" className="" {...register("LastName", { required: true, maxLength: 80 })}></input>
                                </fieldset>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.CountryId ? ' invalid' : '')}>
                                    <legend>Country</legend>
                                    <Controller
                                        name={"CountryId"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                            return (<Select
                                                className={"select-box"}
                                                options={countries ? countries : []}
                                                placeholder={""}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                            />)
                                        }}
                                    />
                                </fieldset>
                            </Col>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.CityId ? ' invalid' : '')}>
                                    <legend>City</legend>
                                    <Controller
                                        name={"CityId"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                            return (<Select
                                                className={"select-box"}
                                                options={cities ? cities : []}
                                                placeholder={""}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                            />)
                                        }}
                                    />
                                </fieldset>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.RelationshipType ? ' invalid' : '')}>
                                    <legend>Relationship</legend>
                                    <Controller
                                        name={"RelationshipType"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                            return (<Select
                                                className={"select-box"}
                                                options={relationship ? relationship : []}
                                                placeholder={""}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                            />)
                                        }}
                                    />
                                </fieldset>
                            </Col>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.Address ? ' invalid' : '')}>
                                    <legend>Address</legend>
                                    <input type="text" className="" {...register("Address", { required: false, maxLength: 80 })}></input>
                                </fieldset>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.Email ? ' invalid' : '')}>
                                    <legend>Email</legend>
                                    <input type="text" className="" {...register("Email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                </fieldset>
                                {errors?.Email ? <div className="invalid text-danger">
                                    {errors.Email.type === "required" ? 'Email Required' : null}
                                    {errors.Email.type === "pattern" ? 'Invalid email address' : null}
                                </div> : null}
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                                            <legend>Code</legend>
                                            <Controller
                                                name={"DialCode"}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => {
                                                    return (<Select
                                                        components={{ Option: dialCodeOptionUI }}
                                                        className={"select-box"}
                                                        options={dialCode ? dialCode : []}
                                                        placeholder={""}
                                                        onChange={onChange}
                                                        value={value}
                                                        defaultValue={value}
                                                    />)
                                                }}
                                            />
                                        </fieldset>
                                    </Col>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.CellPhone ? ' invalid' : '')}>
                                            <legend>Phone number</legend>
                                            <input type="text" className="" {...register("CellPhone", { required: true, minLength: 10, maxLength: 10 })} onKeyPress={(event: any) => handleKeyPressNumber(event)} />
                                        </fieldset>
                                        {errors?.CellPhone ? <div className="invalid text-danger">
                                            {errors.CellPhone.type === "required" ? 'Phone Number is Required' : null}
                                            {errors.CellPhone.type === "maxLength" ? 'Phone Number should be of 10 digit' : null}
                                            {errors.CellPhone.type === "minLength" ? 'Phone Number should be of 10 digit' : null}
                                        </div> : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <div className="mt-4 d-flex gap-2 login-btns">
                        <Button className="btn" variant="success" type='submit'>Save & continue</Button>
                    </div>
                </form>
                :
                <div className="mt-4 d-flex gap-2 login-btns">
                    <Button className="btn" variant="success" type='button' onClick={() => submitStepThree()}>Save & continue</Button>
                </div>
            }
        </React.Fragment>
    )
}