import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';
import "../../styles/modal/createadvisory.scss";
import { useAdvisoryApi } from '../../_common/hooks/api/advisoryApiHook';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';

interface props {
	show: boolean;
	close: () => void;
	getAlerts: Function;
}

const CreateAdvisoryAlertModal = ({ show, close, getAlerts }: props) => {

	const commonApi = useCommonApi()
	const advisoryApi = useAdvisoryApi()
	const { showLoader, hideLoader } = useAppLoader()
	const user: any = useUserSelector()


	const { handleSubmit, control, formState: { errors }, watch, reset } = useForm({ mode: "onChange" });
	const country = watch('CountryId');

	const [countries, setCountries] = useState<any>(null)
	const [cities, setCities] = useState<any>(null)

	const getCountries = () => {
		commonApi.getCountries({},
			(message: string, resp: any) => {
				if (resp.length > 0) {
					setCountries(resp.map((data: any) => {
						data.label = data.xql_name;
						data.value = data.xql_countryid;
						return data;
					}))
				}
			}, (message: string) => {
				console.log(message);
			})
	}

	const getCities = (id: any = country?.value) => {
		return new Promise((resolve: any, reject: any) => {
			commonApi.getCities({
				CountryId: id
			}, (message: string, resp: any) => {
				if (resp.length > 0) {
					setCities(resp.map((data: any) => {
						data.label = data.xql_name;
						data.value = data.xql_cityid;
						return data;
					}))
				}
				setTimeout(() => {
					resolve()
				}, 300)
			}, (message: string) => {
				// console.log(message);
				reject()
			});
		})
	}

	const onSubmit = (data: any) => {
		let params = {
			"ContactId": user?.contactid,
			"CountryId": data.CountryId.value,
			"CityId": data.CityId == undefined ? "all" : data.CityId.value
		}
		showLoader()
		advisoryApi.createAlert(params,
			(message: string, resp: any) => {
				hideLoader()
				close()
				reset({
					CountryId: null,
					CityId: null,
				});
				toast.success(message)
				getAlerts()
			},
			(message: string) => {
				hideLoader()
				close()
				reset({
					CountryId: null,
					CityId: null,
				});
				toast.error(message)
			})
	}

	useEffect(() => {
		getCountries()
	}, [])

	useEffect(() => {
		if (country) {
			getCities()
		}
	}, [country])

	return (
		<>
			<Modal className='opencase-modal' show={show} onHide={() => {
				close();
				reset({
					CountryId: null,
					CityId: null,
				});
			}}
			>
				<Modal.Body>
					<div className="create-advisory-modal">
						<form onSubmit={handleSubmit(onSubmit)}>
							<fieldset className={"input filled" + (errors?.CountryId ? ' invalid' : '')}>
								<legend>Country (you’re travelling/residing into)</legend>
								<Controller
									name={"CountryId"}
									control={control}
									rules={{ required: true }}
									render={({ field: { value, onChange, onBlur } }) => {
										return (<Select
											className={"select-box"}
											options={countries ? countries : []}
											placeholder={""}
											onChange={onChange}
											value={value}
											defaultValue={value}
										/>)
									}}
								/>
							</fieldset>

							<fieldset className={"input filled" + (errors?.CityId ? ' invalid' : '')}>
								<legend>City</legend>
								<Controller
									name={"CityId"}
									control={control}
									rules={{ required: false }}
									render={({ field: { value, onChange, onBlur } }) => {
										return (<Select
											className={"select-box"}
											options={cities ? cities : []}
											placeholder={""}
											onChange={onChange}
											value={value}
											defaultValue={value}
										/>)
									}}
								/>
							</fieldset>

							<div>
								<button className="btn btn-primary w-100" type='submit'>Create Alert</button>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default CreateAdvisoryAlertModal;