import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import '../styles/notification.scss';
import { BsThreeDotsVertical } from "react-icons/bs";

export default function NotificationDetails() {
    return (
        <React.Fragment>
            <Header backMode={{pageName: "Notifications", backUrl: "/notifications"}}/>
            <Container>
                <div className="notification-details-wrapper">
                    <div className="notification-row">
                        <div className="notification-content d-flex align-items-center">
                            <div className="notification-avatar">A</div>
                            <div>
                                <h3>Non-critical Advisory</h3>
                            </div>
                        </div>
                        <div className="notification-more">
                            <BsThreeDotsVertical />
                        </div>
                    </div>
                    <div className="notification-details-pic">
                        <img src="/assets/img/notification-details-pic.jpg" alt="" />
                    </div>
                    <div className="notification-details-content">
                        <h2>Planned industrial action of Thailand air traffic controllers</h2>
                        <h4>Phuket, Thailand</h4>
                        <div className="notification-details-content-inner">
                            <p>Please be informed that one day industrial
                                action of air traffic controllers on
                                Thursday, 8th of December 2016.
                                Anticipate delays and crowded airports.
                                This will affect both inbound and
                                outbound travelers. In case of changes,
                                we will advise accordingly</p>
                        </div>
                    </div>
                    <div className="text-end notification-details-btn">
                        <button className="btn btn-primary">Ok</button>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}