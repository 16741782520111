import { combineReducers } from 'redux';
import cityAlertReducer from './common/cityAlertReducer';
import loaderReducer from './common/loaderReducer'
import userReducer from './user/userReducer'
import countryDirectoryReducer from './countryDirectory/countryDirectoryReducer'

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  cityAlert: cityAlertReducer,
  countryDirectory: countryDirectoryReducer,
})

export default rootReducer