import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/components/travels/noTravelData.scss'


export default function NoTravelData() {

    return (
            <div className='main-div'>
                <h2>Travels</h2>
                <br />
                <br />
                <h4>Please select an option to add your travel</h4>
                <Link to='/new-travel'><Button className='btn' variant='success'>Create Travel</Button></Link>
            </div>     
    )
}