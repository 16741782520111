import { SAGA_ACTIONS } from "../../../_config";
import { getTravelsDetailsType, getTravelsType } from "../../interfaces/ApiReqRes";
import { useApiCall } from "../common/appApiCallHook";



export function useTravelsApi() {

    const callApi = useApiCall();

    const getTravels = (data: getTravelsType, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TRAVELS.GET_TRAVELS, data, onSuccess, onError);
    }

    const getTravelsDetails = (data: getTravelsDetailsType, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TRAVELS.TRAVELS_DETAILS, data, onSuccess, onError);
    }

    const createTravelStepOne = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TRAVELS.STEP1, data, onSuccess, onError);
    }

    const createTravelStepTwo = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TRAVELS.STEP2, data, onSuccess, onError);
    }

    const createTravelStepThree = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TRAVELS.STEP3, data, onSuccess, onError);
    }

    const createTravelStepFour = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TRAVELS.STEP4, data, onSuccess, onError);
    }


    return {
        getTravels: getTravels,
        createTravelStepOne: createTravelStepOne,
        createTravelStepTwo: createTravelStepTwo,
        createTravelStepThree: createTravelStepThree,
        createTravelStepFour: createTravelStepFour,
        getTravelsDetails: getTravelsDetails,
    }
}