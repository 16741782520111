import React from 'react'
import Header from '../components/header'
import { Container } from 'react-bootstrap';
import { BsBell } from "react-icons/bs";
import '../styles/countrydirectory.scss';
import { usePopularCitiesSelector } from '../_common/hooks/selectors/countryDirectorySelector';
import { Link, useNavigate } from 'react-router-dom';

const PopularCities = () => {

    const popularCities = usePopularCitiesSelector()
    const navigate = useNavigate()

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {

            return 'data:image/jpeg;base64,' + url
        }

    }

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="country-directory-wrapper">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Popular Cities</h2>
                        <Link className="btn btn-success my-2" to="/country-directory">Back</Link>
                    </div>
                    <div className="coutry-city-block-wrapper coutry-city-block-wrapper-single">
                        {popularCities && popularCities.length > 0 ?
                            popularCities.map((city: any, index: number) => (
                                <div className="coutry-city-block" key={index} onClick={() => navigate(`/city-info/${city?.CityId}`)}>
                                    <div className="coutry-city-block-pic">
                                        <img src={city?.CityImage && checkImageBase64Code(city?.CityImage)} alt="img" />
                                    </div>
                                    <div className="coutry-city-block-content">
                                        <h4>{city?.Name}</h4>
                                        <h5 className="alert-text"><span><BsBell /></span>{city?.NoOfAlerts}+ Active Alerts</h5>
                                    </div>
                                </div>
                            ))
                            : 
                            <h6 className="alert alert-danger text-center w-100" role="alert">No Data</h6>
                        }
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default PopularCities