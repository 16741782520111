import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useForm } from "react-hook-form";
import '../styles/welcome.scss';
import { Link } from "react-router-dom";

export default function EntryType() {
	const [tab, setTab] = useState<any>('company');
	return (
		
		<div className="card-outer welcome-card-outer">
			<div className="card welcome-card">

				<div className="d-flex gap-3">
					<div className="logo">
						<img src="../assets/img/logo.svg" />
					</div>
					<div>
						<h1>International relations & cooperation</h1>
						<p>Department:<br></br>
							International Relations and Cooperation
							REPUBLIC OF SOUTH AFRICA</p>
					</div>
				</div>
				<div className="welcome-content text-center w-100">
					<h2 className="mb-5">Welcome to DIRCO</h2>
					<h3>Enter your details to proceed further</h3>
				</div>
				<div className="mt-4 w-100 login-btns">
					<Link to="/login" className="btn btn-success w-100 mb-2">Sign in</Link>
					<Link to="/privacy-policy" className="btn w-100" >Sign up</Link>
				</div>
			</div>
		</div>
		
	);
}