import { call } from 'redux-saga/effects';
import { API_URL } from '../../_config'
import { CallApi } from './api/callApi';


export function* getTravels(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.TRAVELS.GET_TRAVELS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getTravelsDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.TRAVELS.TRAVELS_DETAILS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createTravelStepOne(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.TRAVELS.STEP1, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.TravelId) {
            sessionStorage.setItem("TravelId", JSON.stringify(resp.data.TravelId));
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createTravelStepTwo(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.TRAVELS.STEP2, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.NextOfKinId) {
            sessionStorage.setItem("NextOfKinId", JSON.stringify(resp.data.NextOfKinId));
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createTravelStepThree(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.TRAVELS.STEP3, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createTravelStepFour(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.TRAVELS.STEP4, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            sessionStorage.removeItem("TravelId")
            sessionStorage.removeItem("NextOfKinId")
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
