import React, { useState } from "react";
import { Container, Card, Button, Row, Col} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/components/connected/connectionDetails.scss";
interface incomingProps {
    data:any
}


export default function ConnectionDetails({data}:incomingProps) {
    const [_data, set_data] = useState<any>({
        title:'Connection Details',
        img:'/assets/img/Rectangle.svg'
    })
    return (
        <div className="connection-details">
           <Container className="connection-details-container">
                <Row>
                    <Col sm={12} className="connection-details-img-block">
                        <img src={_data.img} className="connection-details-img"/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Row>
                            <Col sm={6}>
                                <Card className="connnection-card">
                                    <Card.Body>
                                        <Card.Title><img src="/assets/img/bell.svg"/></Card.Title>
                                        <Card.Text>
                                            Advisories
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6}>
                               <Card className="connnection-card">
                                    <Card.Body>
                                        <Card.Title><img src="/assets/img/Group.svg"/></Card.Title>
                                        <Card.Text>
                                            Country Directory
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Row>
                            <Col sm={6}>
                            <Card className="connnection-card">
                                    <Card.Body>
                                        <Card.Title><img src="/assets/img/cases.svg"/></Card.Title>
                                        <Card.Text>
                                            Cases
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
           </Container>
        </div>
    )
}