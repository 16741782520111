import React, {useState} from "react";
import { Container } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import "../../styles/components/cases/caseList.scss";
import Connection from "./connection"
interface incomingProps {
    connections:any[];
}

export default function ConnectionList({connections}: incomingProps) {


    return (
        <div className="cases">
            <Container>
            {connections.map((data:any,i:any)=>(
                            <Connection data={data} key={i}/>
                        ))}
            </Container>
        </div>
    )
}