import { useSelector } from 'react-redux'
import { StateExtended } from '../../interfaces/StateExtended'

export function usePopularCountriesSelector() {
    const data = useSelector((state: StateExtended) => state.countryDirectory.popularCountries.popularCountries)
    return data
}

export function usePopularCitiesSelector() {
    const data = useSelector((state: StateExtended) => state.countryDirectory.popularCities.popularCities)
    return data
}