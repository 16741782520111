import { SAGA_ACTIONS } from '../../../_config';
import { getCasesDetailsType, getCasesType } from '../../interfaces/ApiReqRes';
import { useApiCall } from '../common/appApiCallHook';

export function useCasesApi() {

  const callApi = useApiCall();

  const getCases = (data: getCasesType, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CASES.GET_CASES, data, onSuccess, onError);
  }

  const getCasesDetails = (data: getCasesDetailsType, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CASES.CASE_DETAILS, data, onSuccess, onError);
  }

  const getCasesTypes = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CASES.GET_CASES_TYPES, data, onSuccess, onError);
  }

  const createCase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CASES.CREATE_CASE, data, onSuccess, onError);
  }

  const createCaseStepTwo = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CASES.CREATE_CASE_STEP2, data, onSuccess, onError);
  }

  const createCaseDocUpload = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CASES.CREATE_CASE_DOC_UPLOAD, data, onSuccess, onError);
  }

  return {
    getCases: getCases,
    getCasesTypes: getCasesTypes,
    createCase: createCase,
    getCasesDetails: getCasesDetails,
    createCaseStepTwo: createCaseStepTwo,
    createCaseDocUpload: createCaseDocUpload,
  }
}