import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';

import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import Header from "../../components/header";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useUserSelector } from "../../_common/hooks/selectors/userSelector";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import toast from "react-hot-toast";
import * as CountryList from 'country-list-with-dial-code-and-flag';
import moment from "moment";

import '../../styles/profileDetails.scss'
import { handleKeyPressNumber } from "../../_common/functions";

export default function ProfileDetails() {

  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const commonApi = useCommonApi()
  const user: any = useUserSelector()

  const { register, handleSubmit, formState: { errors }, control, reset, watch, getValues } = useForm();
  const country = watch('CountryId');

  const [gender, setGender] = useState<any>(null)
  const [dialCode, setDialCode] = useState<any>(null)
  const [countries, setCountries] = useState<any>(null)
  const [cities, setCities] = useState<any>(null)
  const [setPrevCityId, set_setPrevCityId] = useState<any>(null)
  const [toggle, setToggle] = useState<boolean>(false)

  const onSubmit = (data: any) => {
    console.log(data)
    let params = {
      "EntityName": "UpdateCitizen",
      "ContactId": user?.contactid,
      "FirstName": data.FirstName,
      "LastName": data.LastName,
      "IDNumber": data.IDNumber,
      "DateOfBirth": data.DateOfBirth.split("-").join("/"),
      "Gender": data.Gender.label == "Male" ? 1 : 2,
      "DialCode": data.DialCode.value,
      "MobileNumber": data.MobileNumber
    }
    showLoader();
    userApi.updateProfile(params, (message: string, resp: any) => {
      console.log({ resp })
      hideLoader();
      toast.success(message)
      navigate('/profile-settings')
    }, (message: string) => {
      toast.error(message)
      hideLoader();
    });
  }

  const retrieveProfile = () => {
    showLoader()
    userApi.retrieveProfile({ contact: user.contactid },
      (message: string, resp: any) => {
        console.log({ resp })
        set_setPrevCityId(resp.City);
        reset({
          FirstName: resp?.FirstName,
          LastName: resp?.LastName,
          IDNumber: resp?.IDNumber,
          MobileNumber: resp?.MobilePhone,
          Email: resp?.Email,
          DateOfBirth: moment(resp.DateOfBirth).format('YYYY-MM-DD'),
          Gender: getSelectDataById(resp?.Gender, gender),
          DialCode: getSelectedDialCodeById(resp?.DialCode, dialCode),
          CountryId: getSelectedCountryCityById(resp?.Country, countries),
          Address: resp?.Address,
        })
        setToggle(resp?.PermanentResident)
        hideLoader()
      }, (message: string) => {
        hideLoader()
      })
  }

  const getSelectedDialCodeById = (id: any, data: any) => {
    let option = null
    data?.map((_option: any) => {
      if (_option.dial_code == id) {
        option = _option
      }
    })
    return option
  }

  const getSelectedCountryCityById = (id: any, data: any) => {
    let option = null
    data?.map((_option: any) => {
      if (_option.xql_name == id) {
        option = _option
      }
    })
    return option
  }

  const getSelectDataById = (id: any, data: any) => {
    let option = null
    data?.map((_option: any) => {
      if (_option.Label.UserLocalizedLabel.Label == id) {
        option = _option
      }
    })
    return option
  }

  const retrieveGender = () => {
    commonApi.retrieveGender({ "DropdownName": "xql_gender" },
      (message: string, resp: any) => {
        let options: any = [];
        if (resp.Options && resp.Options.length > 0) {
          resp.Options.map((item: any) => {
            item.value = item.Value
            item.label = item.Label.UserLocalizedLabel.Label
            options.push(item)
          })
          setGender(options)
        }
      }, (message: string) => {
        console.log(message);
      })
  }

  const getCountries = () => {
    commonApi.getCountries({},
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setCountries(resp.map((data: any) => {
            data.label = data.xql_name;
            data.value = data.xql_countryid;
            return data;
          }))
        }
      }, (message: string) => {
        console.log(message);
      })
  }

  const getCities = (id: any = country?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCities({
        CountryId: id
      }, (message: string, resp: any) => {
        if (resp.length > 0) {
          setCities(resp.map((data: any) => {
            data.label = data.xql_name;
            data.value = data.xql_cityid;
            return data;
          }))
        }
        setTimeout(() => {
          resolve()
        }, 300)
      }, (message: string) => {
        // console.log(message);
        reject()
      });
    })
  }

  const getDialCode = (data: any) => {
    if (data.length > 0) {
      setDialCode(data.map((item: any) => {
        item.label = item.dial_code;
        item.value = item.dial_code;
        return item;
      }))
    }
  }

  const dialCodeOptionUI = (props: any) => {
    const { innerProps, innerRef, data } = props;
    return (
      <div ref={innerRef} {...innerProps} className="dial-code-option">
        {data.flag}
        <span>{data.dial_code}</span>
      </div>
    );
  }

  useEffect(() => {
    if (user && gender && dialCode && countries) {
      retrieveProfile()
    }
  }, [user, gender, dialCode, countries])

  useEffect(() => {
    if (cities) {
      if (setPrevCityId) {
        let opt: any = getSelectedCountryCityById(setPrevCityId, cities);
        set_setPrevCityId(null)
        // console.log(opt)
        // console.log({...getValues(), ...{campus: opt}})
        reset({
          ...getValues(), CityId: opt
        })
      }
    }
  }, [cities])

  useEffect(() => {
    if (country) {
      getCities()
    }
  }, [country])

  useEffect(() => {
    getCountries()
    retrieveGender()
    getDialCode(CountryList.getList())
  }, [])

  return (
    <React.Fragment>
      <Header backMode={{ pageName: "Personal Details", backUrl: "/profile-settings" }} />
      <Container>
        <div className="card-outer signup-wrapper-outer">
          <div className="card">
            <h1 className="text-center">Profile Details</h1>
            <form onSubmit={handleSubmit(onSubmit)} className='w-100' autoComplete='off'>
              <div className="container-fluid p-0">
                <Row>
                  <Col sm={6}>
                    <fieldset className={"input" + (errors?.FirstName ? ' invalid' : '')}>
                      <legend>First name</legend>
                      <input type="text" className="" placeholder='' {...register("FirstName", { required: true, maxLength: 80 })} />
                    </fieldset>
                  </Col>
                  <Col sm={6}>
                    <fieldset className={"input" + (errors?.LastName ? ' invalid' : '')}>
                      <legend>Last name</legend>
                      <input type="text" className="" {...register("LastName", { required: true, maxLength: 80 })} />
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <fieldset className={"input" + (errors?.DateOfBirth ? ' invalid' : '')}>
                      <legend>Date of birth</legend>
                      <input type="date" className="" placeholder='' {...register("DateOfBirth", { required: true, maxLength: 80 })} />
                    </fieldset>
                  </Col>
                  <Col sm={6}>
                    <fieldset className={"input" + (errors?.Gender ? ' invalid' : '')}>
                      <legend>Gender</legend>
                      <Controller
                        name={"Gender"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (<Select
                            className={"select-box"}
                            options={gender ? gender : []}
                            placeholder={""}
                            onChange={onChange}
                            value={value}
                            defaultValue={value}
                          />)
                        }}
                      />
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <fieldset className={"input" + (errors?.Email ? ' invalid' : '')}>
                      <legend>Email</legend>
                      <input type="email" className="" placeholder='' {...register("Email", { required: true, maxLength: 80, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                    </fieldset>
                    {errors?.Email ? <div className="invalid text-danger">
                      {errors.Email.type === "required" ? 'Email Required' : null}
                      {errors.Email.type === "pattern" ? 'Invalid email address' : null}
                    </div> : null}
                  </Col>
                  <Col sm={6}>
                    <Row>
                      <Col sm={5}>
                        <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                          <legend>Code</legend>
                          <Controller
                            name={"DialCode"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => {
                              return (<Select
                                components={{ Option: dialCodeOptionUI }}
                                className={"select-box"}
                                options={dialCode ? dialCode : []}
                                placeholder={""}
                                onChange={onChange}
                                value={value}
                                defaultValue={value}
                              />)
                            }}
                          />
                        </fieldset>
                      </Col>
                      <Col sm={7}>
                        <fieldset className={"input" + (errors?.MobileNumber ? ' invalid' : '')}>
                          <legend>Phone number</legend>
                          <input type="text" className="" {...register("MobileNumber", { required: true, minLength: 10, maxLength: 10 })} onKeyPress={(event: any) => handleKeyPressNumber(event)} />
                        </fieldset>
                        {errors?.MobileNumber ? <div className="invalid text-danger">
                          {errors.MobileNumber.type === "required" ? 'Phone Number is Required' : null}
                          {errors.MobileNumber.type === "maxLength" ? 'Phone Number should be of 10 digit' : null}
                          {errors.MobileNumber.type === "minLength" ? 'Phone Number should be of 10 digit' : null}
                        </div> : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <fieldset className={"input" + (errors?.IDNumber ? ' invalid' : '')}>
                      <legend>ID number</legend>
                      <input type="text" className="" placeholder='' {...register("IDNumber", { required: true, maxLength: 80 })} onKeyPress={(event: any) => handleKeyPressNumber(event)} />
                    </fieldset>
                    {errors?.IDNumber ? <div className="invalid text-danger">
                      {errors.IDNumber.type === "required" ? 'ID Number is Required' : null}
                      {errors.IDNumber.type === "maxLength" ? 'Maximum  80 digit' : null}
                    </div> : null}
                  </Col>
                </Row>

                <div className="d-flex justify-content-between mt-5">
                  <h2>Are you a permanent resident abroad?</h2>
                  <div>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => setToggle(!toggle)} checked={toggle}></input>
                      <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                    </div>
                  </div>
                </div>

                {
                  toggle ?
                    <React.Fragment>
                      <Row>
                        <Col sm={6}>
                          <fieldset className={"input" + (errors?.CountryId ? ' invalid' : '')}>
                            <legend>Select country</legend>
                            <Controller
                              name={"CountryId"}
                              control={control}
                              rules={{ required: toggle }}
                              render={({ field: { value, onChange, onBlur } }) => {
                                return (<Select
                                  className={"select-box"}
                                  options={countries ? countries : []}
                                  placeholder={""}
                                  onChange={onChange}
                                  value={value}
                                  defaultValue={value}
                                />)
                              }}
                            />
                          </fieldset>
                        </Col>
                        <Col sm={6}>
                          <fieldset className={"input" + (errors?.CityId ? ' invalid' : '')}>
                            <legend>City</legend>
                            <Controller
                              name={"CityId"}
                              control={control}
                              rules={{ required: toggle }}
                              render={({ field: { value, onChange, onBlur } }) => {
                                return (<Select
                                  className={"select-box"}
                                  options={cities ? cities : []}
                                  placeholder={""}
                                  onChange={onChange}
                                  value={value}
                                  defaultValue={value}
                                />)
                              }}
                            />
                          </fieldset>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <fieldset className={"input" + (errors?.Address ? ' invalid' : '')}>
                            <legend>Address</legend>
                            <textarea className="" {...register("Address", { required: toggle, maxLength: 80 })} />
                          </fieldset>
                        </Col>
                      </Row>
                    </React.Fragment>
                    : null
                }

              </div>

              <div className="mt-4 d-flex gap-2 login-btns">
                <Button variant='success' type='submit'>Save</Button>
              </div>
            </form>

          </div>
        </div>
      </Container>



    </React.Fragment>



  );
}