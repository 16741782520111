import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import Case from "./case";
import "../../styles/components/cases/caseList.scss";
import { Link } from "react-router-dom";

interface incomingProps {
    cases: any[];
    activeCases: any[];
    resolvedCases: any[];
}

export default function CasesList({ cases, activeCases, resolvedCases }: incomingProps) {

    const [apiCall, setApiCall] = useState<boolean>(false);
    const [tabId, setTabId] = useState('unresolved');

    const tabList = [
        // {
        //     id: 'all',
        //     label: "All"
        // },
        {
            id: 'unresolved',
            label: "Unresolved"
        },
        {
            id: 'resolved',
            label: "Resolved"
        }
    ];


    const handelClick = (e: any) => {
        setTabId(e.target.id);
    }
    
    useEffect(() => {
        if (apiCall) {
            setApiCall(false)
        }
    }, [apiCall])

    return (
        <React.Fragment>
            <div className="cases caselist-wrapper">
                <div className="d-flex justify-content-between align-items-center w-100 caselist-heading">
                    <h2>Cases</h2>
                    <Link to={'/create-case'}><Button type="button" className="btn btn-success">Create case</Button></Link>
                </div>
                <Container>
                    <ListGroup className="cases-tab" onClick={(e) => handelClick(e)}>
                        {
                            tabList.map((item, i) => (
                                <ListGroup.Item key={i} className={tabId == item.id ? "case-tab-li active" : "case-tab-li"} id={item.id}>{item.label}</ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Container>
                {
                    tabList.map((item, i) => (
                        <Container className={tabId == item.id ? "tab-content active" : "tab-content"} key={i}>
                            {/* {tabId == 'all' && item.id == 'all' ?
                                cases.length > 0 && cases.map((data: any, i: any) => (
                                    <Case data={data} key={i} />
                                ))
                                : null
                            } */}
                            {tabId == 'unresolved' && item.id == 'unresolved' ?
                                activeCases.length > 0 && activeCases.map((data: any, i: any) => (
                                    <Case data={data} key={i} />
                                ))
                                : null
                            }
                            {tabId == 'resolved' && item.id == 'resolved' ?
                                resolvedCases.length > 0 && resolvedCases.map((data: any, i: any) => (
                                    <Case data={data} key={i} />
                                ))
                                : null
                            }
                        </Container>
                    ))
                }


            </div>
        </React.Fragment>
    )
}


