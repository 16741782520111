import React from 'react'

interface incomingProps {
    img: string;
    text: string;
}

const LeftPanel = ({ img, text }: incomingProps) => {
    

    return (
        <React.Fragment>
            <div className="left-sidebar-panel">
                <img src={img} alt="" />
                <h2>{text}</h2>
            </div>
        </React.Fragment>
    )
}

export default LeftPanel