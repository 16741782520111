import { SAGA_ACTIONS } from "../../../_config";
import { getAlertByType, retrieveProfileType } from "../../interfaces/ApiReqRes";
import { useApiCall } from "../common/appApiCallHook";



export function useAdvisoryApi() {

    const callApi = useApiCall();

    const createAlert = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADVISORY.CREATE_ALERT, data, onSuccess, onError);
    }

    const getContactAlert = (data: retrieveProfileType, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADVISORY.GET_CONTACT_ALERT, data, onSuccess, onError);
    }

    const retrieveAlertType = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADVISORY.RETRIEVE_ALERT_TYPE, data, onSuccess, onError);
    }

    const getAlertByType = (data: getAlertByType, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADVISORY.GET_ALERT_BY_TYPE, data, onSuccess, onError);
    }


    return {
        createAlert: createAlert,
        getContactAlert: getContactAlert,
        retrieveAlertType: retrieveAlertType,
        getAlertByType: getAlertByType,
    }
}