import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Step1 from "../components/newTravel/step1";
import Step2 from "../components/newTravel/step2";
import Step3 from "../components/newTravel/step3";
import '../styles/travel.scss';
import Header from "../components/header";
import { Container } from "react-bootstrap";
import Step0 from "../components/newTravel/step0";
import LeftPanel from "../components/leftPanel";

export default function NewTravel() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const step: any = searchParams.get('step');

  useEffect(() => {
    if (step === null) {
      navigate('/new-travel?step=0')
    }
  }, [])

  return (
    <React.Fragment>
      <Header />
      <Container>
      <div className="wrapper-left-right-panel">
      <LeftPanel img={"/assets/img/SideSlide-2.jpg"} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."}/>
        <div className="card-outer travel-wrapper-outer">
          <div className="card">
            {(step == 0) ? <Step0 /> : null}
            {(step == 1) ? <Step1 /> : null}
            {(step == 2) ? <Step2 /> : null}
            {(step == 3) ? <Step3 /> : null}
          </div>
        </div>
        </div>
      </Container>
    </React.Fragment>
  )
}