import React from "react";
import { Button, Container} from "react-bootstrap";
import "../../styles/components/cases/noCases.scss";
import { Link } from "react-router-dom";



export default function NoCases() {


    return (
        <React.Fragment>
            
            <div className="no-cases w-100">
                <Container>
                    <div className="wrapper-left-right-panelc w-100">
                        <div className="w-100">
                            <img src="/assets/img/undraw_questions.svg" alt="NOCASE" />
                            <h1 className="text2">You do not have any cases currently</h1>
                            <h3>You can easily create new cases, click the button below get started</h3>
                            {/* <Button className="w-100" onClick={() => { setCreateCase(true) }}>Create case</Button> */}
                            <Link to={'/create-case'}><Button type="button" className="w-100">Create case</Button></Link>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}