import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/header";
import NoCases from "../components/cases/noCases";
import CasesList from "../components/cases/casesList";

import "../styles/components/connect/connected.scss";
import { Link } from "react-router-dom";

export default function ConnectRequest() {
    const [cases,setCases] = useState<any>([{},{}]);
    return (
        <React.Fragment>
            <Header backMode={{pageName: "Notifications", backUrl: "/profile"}}/>
            <Container>
                <div className="connected-wrapper">
                    <h4>Connect with a loved one</h4>
                    <p>Your connection request has been sent successfully.</p>
                    <div className="connection-card">
                        <a className="connection-link"> 
                            <Row>
                                <Col sm={2}><img src="./assets/img/attachment-pic-5.jpg" className="connection-iamge"/></Col>
                                <Col sm={8} className="content">
                                    <h4>Van Zyl</h4>
                                    <p><img src="/assets/img/bell.svg" className="notification"/> Connected</p>
                                </Col>
                                <Col sm={2} className="go-to-details"><img src="/assets/img/Vector.svg" className="connection-details"/></Col>
                            </Row>
                        </a>
                    </div>
                    <div className="d-flex gap-2 pt-4">
                        <button className="btn btn-success w-100">Accept</button>
                        <button className="btn btn-default w-100">Decline</button>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}