import {Outlet, Navigate} from 'react-router-dom';
import { useAuthStatus } from '../_common/hooks/authHook';
const PrivateRoutes = () => {
    const isAuth = useAuthStatus();
    // console.log('is auth =>',isAuth)
    return (
        isAuth?<Outlet/>:<Navigate to="/welcome" />
    )
}

export default  PrivateRoutes;