import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import NoCases from "../components/cases/noCases";
import CasesList from "../components/cases/casesList";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useCasesApi } from "../_common/hooks/api/casesApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import LeftPanel from "../components/leftPanel";

export default function Cases() {

    const caseApi = useCasesApi()
    const { showLoader, hideLoader } = useAppLoader()
    const user: any = useUserSelector()

    const [cases, setCases] = useState<any>(null)
    const [activeCases, setActiveCases] = useState<any>(null)
    const [resolvedCases, setResolvedCases] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)


    const getActiveCases = () => {
        showLoader()
        setLoading(true)
        caseApi.getCases({
            contact: user.contactid,
            status: 0
        },
            (message: string, resp: any) => {
                hideLoader()
                setLoading(false)
                // console.log("act =>>",{ resp }, Array.isArray(resp))
                if (Array.isArray(resp)) {
                    setActiveCases(resp)
                    setCases(resp)
                }
                else {
                    setActiveCases([])
                    setCases([])
                }
                getResolvedCases()
            }, (message: string) => {
                hideLoader()
                setLoading(false)
                console.log(message);
            })
    }

    const getResolvedCases = () => {
        showLoader()
        setLoading(true)
        caseApi.getCases({
            contact: user.contactid,
            status: 1
        },
            (message: string, resp: any) => {
                hideLoader()
                setLoading(false)
                // console.log("resol =>>",{ resp }, Array.isArray(resp))
                if (Array.isArray(resp)) {
                    setResolvedCases(resp)
                    setCases([...cases, ...resp])
                }
                else {
                    setResolvedCases([])
                }
            }, (message: string) => {
                hideLoader()
                setLoading(false)
                console.log(message);
            })
    }

    useEffect(() => {
        if (cases == null && user) {
            getActiveCases()
        }
        else {
            // console.log("cases ==>",{cases})
        }
    }, [cases, user])

    return (
        <React.Fragment>
            <Header active={"cases"}/>
            {loading ?
                null
                :
                <Container>
                    <div className="wrapper-left-right-panel">
                        <LeftPanel img={"/assets/img/SideSlide.jpg"} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."}/>
                        {cases && cases.length > 0 ? <CasesList cases={cases} activeCases={activeCases} resolvedCases={resolvedCases}/> : <NoCases/>}
                    </div>
                    
                    
                </Container>
            }
        </React.Fragment>
    )
}




