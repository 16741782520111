import React from "react";
import { Button, Container } from "react-bootstrap";
import "../../styles/components/cases/noCases.scss"
export default function NoConnection() {
    return (
        <div className="no-connection">
            <Container>
                <h3>No Connection available..</h3>N
            </Container>
        </div>
    )
}