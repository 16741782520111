import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";



export function useCountryDirectoryApi() {

    const callApi = useApiCall();

    const getPopularCountries = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COUNTRY_DIRECTORY.POPULAR_COUNTRIES, data, onSuccess, onError);
    }

    const getPopularCities = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COUNTRY_DIRECTORY.POPULAR_CITIES, data, onSuccess, onError);
    }


    return {
        getPopularCountries: getPopularCountries,
        getPopularCities: getPopularCities,
    }
}