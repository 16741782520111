import {Outlet, Navigate} from 'react-router-dom';
import { useAuthStatus, useChangePasswordHook } from '../_common/hooks/authHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import { URLS } from './index';
const OpenRoutes = () => {
    const isAuth = useAuthStatus();
    const user: any = useUserSelector();
    const changePassword = useChangePasswordHook();
    console.log('user=> ', user)
    return (
        isAuth?changePassword?<Navigate to={URLS.RESET_PASSWORD} />:<Navigate to={URLS.HOME} />:<Outlet/>
    )

}

export default  OpenRoutes;