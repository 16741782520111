import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../../_config'

export interface CityAlertReducer {
  data: any;
}

const initialState: CityAlertReducer = {
  data: null,
};

const cityAlertReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COMMON.GET_CITY_ALERT:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default cityAlertReducer;
