import React from 'react';
import '../../styles/components/signup/stepIndicators.scss';

interface incomingProps {
    step: number;
}

export default function StepIndicators({ step }: incomingProps) {
    return (        
        <div className='step-indicators'>
            <div className={'step' + (step >= 1 ? ' active' : '')}>
                <div className='circle'></div>
                <label>Step 1</label>
            </div>
            <div className='line'></div>
            <div className={'step' + (step >= 2 ? ' active' : '')}>
                <div className='circle'></div>
                <label>Step 2</label>
            </div>
            <div className='line'></div>
            <div className={'step' + (step >= 3 ? ' active' : '')}>
                <div className='circle'></div>
                <label>Step 3</label>
            </div>
            <div className='line'></div>
            <div className={'step' + (step >= 4 ? ' active' : '')}>
                <div className='circle'></div>
                <label>Step 4</label>
            </div>
        </div>
    )
}