import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../../_config'
import { CallApi } from './api/callApi';


export function* getPopularCountries(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COUNTRY_DIRECTORY.POPULAR_COUNTRIES, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            yield put({
                type: ACTIONS.COUNTRY_DIRECTORY.POPULAR_COUNTRIES,
                payload: {
                    popularCountries:  resp.data.Countries,
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getPopularCities(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COUNTRY_DIRECTORY.POPULAR_CITIES, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            yield put({
                type: ACTIONS.COUNTRY_DIRECTORY.POPULAR_CITIES,
                payload: {
                    popularCities:  resp.data.Cities,
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
