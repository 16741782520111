import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import NoConnection from "../components/connection/noConnection";
import ConnectionList from "../components/connection/connectionList";

import "../styles/components/connect/connected.scss";
import LeftPanel from "../components/leftPanel";

export default function Connected() {
    const [connection,setConnection] = useState<any>([{},{}]);
    return (
        <React.Fragment>
            <Header backMode={{pageName: "Connect", backUrl: "/connect"}} />
            <Container>
                <div className="wrapper-left-right-panel">
                <LeftPanel img={"/assets/img/SideSlide.jpg"} text={"For assistance when abroad contact the nearest South African Representative or the Department, Chief Directorate: Consular Services in Pretoria."}/>
                    <div className="connected-wrapper">
                        <h4>Connect with a loved one</h4>
                        <p>Your connection request has been accepted, you may now trace their movement.</p>
                        {connection && connection.length>0?<ConnectionList connections={connection}/>:<NoConnection/>}
                        <div>
                            <img src="../assets/img/connected-map.jpg" />
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}