import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/header'
import { useCountryDirectoryApi } from '../_common/hooks/api/countryDirectoryApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { Container } from 'react-bootstrap'
import { BsBell, BsSearch } from "react-icons/bs"
import '../styles/countrydirectory.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useAdvisoryApi } from '../_common/hooks/api/advisoryApiHook'

const CountryDirectory = () => {

    const directoryApi = useCountryDirectoryApi()
    const advisoryApi = useAdvisoryApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()

    const [popularCountries, setPopularCountries] = useState<any>([])
    const [popularCities, setPopularCities] = useState<any>([])
    const [alertTypes, setAlertTypes] = useState<any>(null)
    const [alertTypesID, setAlertTypesID] = useState<any>(0)
    const [searchKey, setSearchKey] = useState<string>('')
    const search: any = useRef();

    const getPopularCountries = () => {
        showLoader()
        directoryApi.getPopularCountries({},
            (message: string, resp: any) => {
                hideLoader()
                // console.log({ countries: resp })
                setPopularCountries(resp.Countries)
            },
            (message: string) => {
                hideLoader()
                console.log({ message })
            })
    }

    const getPopularCities = () => {
        showLoader()
        directoryApi.getPopularCities({},
            (message: string, resp: any) => {
                hideLoader()
                // console.log({ cities: resp })
                setPopularCities(resp.Cities)
            },
            (message: string) => {
                hideLoader()
                console.log({ message })
            })
    }

    const retrieveAlertType = () => {
        // showLoader()
        advisoryApi.retrieveAlertType({ "DropdownName": "xql_alerttypes" },
            (message: string, resp: any) => {
                // hideLoader()
                // console.log({ alertype: resp })
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setAlertTypes(options)
                }
            },
            (message: string) => {
                // hideLoader()
                console.log({ message })
            })
    }

    const getAlertByType = (alertType?: any) => {
        showLoader()
        advisoryApi.getAlertByType({ alerttype: alertType },
            (message: string, resp: any) => {
                hideLoader()
                // console.log({ alertype: resp })
                setPopularCities(resp?.Cities)
                setPopularCountries(resp?.Countries)
            },
            (message: string) => {
                hideLoader()
                console.log({ message })
            })
    }

    const handleSearch = (txt: string) => {
        setSearchKey(txt);
    }

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {

            return 'data:image/jpeg;base64,' + url
        }

    }

    useEffect(() => {
        search.current.addEventListener('search', () => {
            handleSearch(search.current.value);
        })
    }, [])

    useEffect(() => {
        getPopularCountries()
        getPopularCities()
        retrieveAlertType()
    }, [])

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="country-directory-wrapper">
                    <h2>Explore</h2>
                    <div className="country-directory-search">
                        <fieldset className="input">
                            <legend>Search</legend>
                            <input placeholder="Search a country or city" type="search" ref={search} onKeyUp={() => { handleSearch(search.current.value) }} />
                            <span><BsSearch /></span>
                        </fieldset>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Popular Countries</h2>
                        <span className="see-all"><Link to='/popular-countries'>See All</Link></span>
                    </div>
                    <div className="tag-filter-btns-panel">
                        <button className={alertTypesID === 0 ? "tag-filter-btn selected" : "tag-filter-btn"} onClick={() => { getPopularCountries(); getPopularCities(); setAlertTypesID(0) }} >Default</button>
                        {alertTypes && alertTypes.length > 0 ?
                            alertTypes.map((item: any, index: number) => (
                                <button className={alertTypesID === item.value ? "tag-filter-btn selected" : "tag-filter-btn"} onClick={() => { getAlertByType(item.value); setAlertTypesID(item.value) }} key={index}>{item.label}</button>
                            ))
                            : null
                        }
                    </div>

                    <div className="coutry-city-block-wrapper mb-4">
                        {popularCountries.length > 0 ?
                            popularCountries.map((country: any, index: number) => {

                                if (searchKey !== '') {
                                    if (country?.Name.toLocaleLowerCase().search(searchKey.toLocaleLowerCase()) >= 0) {
                                        return (
                                            <div className="coutry-city-block" key={index} onClick={() => navigate(`/country-info/${country?.CountryId}`)}>
                                                <div className="coutry-city-block-pic">
                                                    <img src={country?.CountryImage && checkImageBase64Code(country?.CountryImage)} alt="img" />
                                                </div>
                                                <div className="coutry-city-block-content">
                                                    <h4>{country?.Name}</h4>
                                                    <h5 className="alert-text"><span><BsBell /></span>{country?.NoOfAlerts}+ Active Alerts</h5>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        <h6 className="alert alert-danger text-center w-100" role="alert">No Data</h6>
                                    }
                                } else {
                                    return (
                                        <div className="coutry-city-block" key={index} onClick={() => navigate(`/country-info/${country?.CountryId}`)}>
                                            <div className="coutry-city-block-pic">
                                                <img src={country?.CountryImage && checkImageBase64Code(country?.CountryImage)} alt="img" />
                                            </div>
                                            <div className="coutry-city-block-content">
                                                <h4>{country?.Name}</h4>
                                                <h5 className="alert-text"><span><BsBell /></span>{country?.NoOfAlerts}+ Active Alerts</h5>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            :
                            <h6 className="alert alert-danger text-center w-100" role="alert">No Data</h6>
                        }
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Popular Cities</h2>
                        <span className="see-all"><Link to='/popular-cities'>See All</Link></span>
                    </div>

                    <div className="coutry-city-block-wrapper">
                        {popularCities.length > 0 ?
                            popularCities.map((city: any, index: number) => {

                                if (searchKey !== '') {
                                    if (city?.Name.toLocaleLowerCase().search(searchKey.toLocaleLowerCase()) >= 0) {
                                        return (
                                            <div className="coutry-city-block" key={index} onClick={() => navigate(`/city-info/${city?.CityId}`)}>
                                                <div className="coutry-city-block-pic">
                                                    <img src={city?.CityImage && checkImageBase64Code(city?.CityImage)} alt="img" />
                                                </div>
                                                <div className="coutry-city-block-content">
                                                    <h4>{city?.Name}</h4>
                                                    <h5 className="alert-text"><span><BsBell /></span>{city?.NoOfAlerts}+ Active Alerts</h5>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        <h6 className="alert alert-danger text-center w-100" role="alert">No Data</h6>
                                    }
                                } else {
                                    return (
                                        <div className="coutry-city-block" key={index} onClick={() => navigate(`/city-info/${city?.CityId}`)}>
                                            <div className="coutry-city-block-pic">
                                                <img src={city?.CityImage && checkImageBase64Code(city?.CityImage)} alt="img" />
                                            </div>
                                            <div className="coutry-city-block-content">
                                                <h4>{city?.Name}</h4>
                                                <h5 className="alert-text"><span><BsBell /></span>{city?.NoOfAlerts}+ Active Alerts</h5>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            :
                            <h6 className="alert alert-danger text-center w-100" role="alert">No Data</h6>
                        }
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default CountryDirectory