import React, { useRef, useState } from "react";
import { Container, Button } from "react-bootstrap";
import Header from "../components/header";
import NoCases from "../components/cases/noCases";
import CasesList from "../components/cases/casesList";
import toast from 'react-hot-toast';

import "../styles/components/connect/connect.scss";
import LeftPanel from "../components/leftPanel";

export default function Connect() {
    const [cases,setCases] = useState<any>([{},{}]);
    const contactid:any = useRef();
    const getCitizenById = (id:any)=>{
        return new Promise((resolve:any,reject:any)=>{
            setTimeout(()=>{
                resolve({});
            },2000)
        })
    }
    const connect = ()=>{
        getCitizenById(contactid.current.value).then((person:any)=>{
            toast(
                (t) => (
                  <div className="connect-modal">
                    You are connecting to
                    <br />
                    <b>{contactid.current.value}</b>
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        className="btn btn-success btn-sm m-1"
                        onClick={() => {
                          toast.dismiss(t.id);
                          toast.promise(
                            makeContactRequest(),
                            {
                              loading: "Connecting...",
                              success: <b>Connected Successfully!</b>,
                              error: (msg)=>{return <b>{msg}</b>},
                            },
                            { duration: 2000 }
                          );
                        }}
                      >
                        Continue
                      </button>
          
                      <button
                        className="btn btn-dark btn-sm m-1"
                        onClick={() => toast.dismiss(t.id)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ),
                { duration: 15000 }
              );
        }).catch((err)=>{
            toast.error(err)
        })
        
        
    }
    const makeContactRequest = ()=>{
        return new Promise((resolve:any,reject:any)=>{
            setTimeout(()=>{
                resolve({});
            },2000)
        })
    }
    return (
        <React.Fragment>
            <Header backMode={{pageName: "Connect", backUrl: "/"}}/>
            <Container>
            <div className="wrapper-left-right-panel">
              <LeftPanel img={"/assets/img/SideSlide.jpg"} text={"For assistance when abroad contact the nearest South African Representative or the Department, Chief Directorate: Consular Services in Pretoria."}/>
              <div className="connect-wrapper">
                <div className="card">
                  <h3>Connect with a loved one</h3>
                  <div className="connect-fld">
                    <p>Or enter their user code below to connect</p>

                    <fieldset>
                      <legend>Code</legend>
                      <input type="text" placeholder="00000000-0000-000000-000" ref={contactid} />
                    </fieldset>
                    <Button onClick={connect}>Connect</Button>
                  </div>
                </div>
              </div>
            </div>
            </Container>
        </React.Fragment>
    )
}