import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import StepIndicators from './stepIndicators';
import * as CountryList from 'country-list-with-dial-code-and-flag';
import { handleKeyPressNumber } from '../../_common/functions';

interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}


export default function Step2({ onSubmitAddressForm, tab }: incomingProps) {

    const { register, handleSubmit, formState: { errors }, watch, control } = useForm()
    const country = watch('CountryId');
    let ProfileId: any = sessionStorage.getItem("ProfileId")

    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()
    const navigate = useNavigate()

    const [toggle, setToggle] = useState<boolean>(true)
    const [countries, setCountries] = useState<any>(null)
    const [cities, setCities] = useState<any>(null)
    const [dialCode, setDialCode] = useState<any>(null)
    const [profileId, setProfileId] = useState<any>(null)

    const onSubmit = (data: any) => {
        let params = {
            "EntityName": "Citizen",
            "ContactId": profileId,
            "CountryId": data.CountryId.value,
            "CityId": data.CityId.value,
            "PermanentResidenceAbroad": toggle,
            "Address": data.Address,
            "DialCode": data.DialCode.value,
            "Telephone": data.Telephone
        }
        console.log({ data })
        showLoader();
        userApi.registerStepTwo(params, (message: string, resp: any) => {
            console.log({ resp })
            hideLoader();
            toast.success(message)
            navigate('/signup?step=3')
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCountries(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_countryid;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getCities = (id: any = country?.value) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.getCities({
                CountryId: id
            }, (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCities(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_cityid;
                        return data;
                    }))
                }
                setTimeout(() => {
                    resolve()
                }, 300)
            }, (message: string) => {
                // console.log(message);
                reject()
            });
        })
    }

    const getDialCode = (data: any) => {
        if (data.length > 0) {
            setDialCode(data.map((item: any) => {
                item.label = item.dial_code;
                item.value = item.dial_code;
                return item;
            }))
        }
    }

    const dialCodeOptionUI = (props: any) => {
        const { innerProps, innerRef, data } = props;
        return (
            <div ref={innerRef} {...innerProps} className="dial-code-option">
                {data.flag}
                <span>{data.dial_code}</span>
            </div>
        );
    }

    useEffect(() => {
        if (country) {
            getCities()
        }
    }, [country])

    useEffect(() => {
        getCountries()
        getDialCode(CountryList.getList())
    }, [])

    useEffect(() => {
        if (ProfileId == 'undefined') {
            setProfileId(null)
        } else {
            setProfileId(JSON.parse(ProfileId))
        }
    }, [ProfileId])

    return (
        <React.Fragment>

            <h1>Create Profile</h1>
            <StepIndicators step={2} />
            <div className="d-flex justify-content-between mt-5">
                <h2>Are you a permanent resident abroad?</h2>
                <div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => setToggle(!toggle)} defaultChecked={toggle}></input>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                    </div>
                </div>
            </div>

            {
                toggle ?
                    <React.Fragment>
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                            <Row>
                                <Col sm={6}>
                                    <fieldset className={"input" + (errors?.CountryId ? ' invalid' : '')}>
                                        <legend>Select country</legend>
                                        <Controller
                                            name={"CountryId"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => {
                                                return (<Select
                                                    className={"select-box"}
                                                    options={countries ? countries : []}
                                                    placeholder={""}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                />)
                                            }}
                                        />
                                    </fieldset>
                                </Col>
                                <Col sm={6}>
                                    <fieldset className={"input" + (errors?.CityId ? ' invalid' : '')}>
                                        <legend>City</legend>
                                        <Controller
                                            name={"CityId"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => {
                                                return (<Select
                                                    className={"select-box"}
                                                    options={cities ? cities : []}
                                                    placeholder={""}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                />)
                                            }}
                                        />
                                    </fieldset>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <fieldset className={"input" + (errors?.Address ? ' invalid' : '')}>
                                        <legend>Address</legend>
                                        <textarea className="" {...register("Address", { required: true, maxLength: 80 })} />
                                    </fieldset>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5}>
                                    <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                                        <legend>Code</legend>
                                        <Controller
                                            name={"DialCode"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => {
                                                return (<Select
                                                    components={{ Option: dialCodeOptionUI }}
                                                    className={"select-box"}
                                                    options={dialCode ? dialCode : []}
                                                    placeholder={""}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                />)
                                            }}
                                        />
                                    </fieldset>
                                </Col>
                                <Col sm={7}>
                                    <fieldset className={"input" + (errors?.Telephone ? ' invalid' : '')}>
                                        <legend>Phone number</legend>
                                        <input type="text" className="" {...register("Telephone", { required: true, minLength: 10, maxLength: 10 })} onKeyPress={(event: any) => handleKeyPressNumber(event)} />
                                    </fieldset>
                                    {errors?.Telephone ? <div className="invalid text-danger">
                                        {errors.Telephone.type === "required" ? 'Phone Number is Required' : null}
                                        {errors.Telephone.type === "maxLength" ? 'Phone Number should be of 10 digit' : null}
                                        {errors.Telephone.type === "minLength" ? 'Phone Number should be of 10 digit' : null}
                                    </div> : null}
                                </Col>
                            </Row>
                            <div className="mt-4 d-flex gap-2 login-btns">
                                <Button className="btn" variant="success" type='submit'>Save & continue</Button>
                                <Link to="/welcome"><Button className="btn btn-transparent" variant="secondary">Cancel</Button></Link>
                            </div>
                        </form>
                    </React.Fragment>
                    :

                    <div className="mt-4 d-flex gap-2 login-btns">
                        <Link to='/signup?step=3'><Button variant='success'>Skip</Button></Link>
                        <Link to="/welcome"><Button className="btn btn-transparent" variant="secondary">Cancel</Button></Link>
                    </div>
            }



        </React.Fragment>
    )
}