import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;


export function* login(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.contactid) {
             sessionStorage.setItem(STORAGE, JSON.stringify(resp.data));
             yield put({
                 type: ACTIONS.USER.LOGIN,
                 payload: {
                     user:  resp.data,
                     token: null,
                 }
             })
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* logout(action: any): any {
    try {
        sessionStorage.removeItem(STORAGE)
        sessionStorage.removeItem("dirco")
        sessionStorage.removeItem("CityId")
        yield put({
            type: ACTIONS.USER.LOGOUT,
        });
        action && action.callbackSuccess && action.callbackSuccess({});
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* post(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_BASE_URL+'/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess  && resp.data.outputParameters) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getprivacyPolicy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.PRIVACY_POLICY, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepOne(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP1, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             sessionStorage.setItem("ProfileId", JSON.stringify(resp.data.ProfileId));
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepTwo(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP2, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepThree(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP3, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepFour(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP4, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrieveProfile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.RETRIEVE_PROFILE, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateProfile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.UPDATE_PROFILE, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}