import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Container } from 'react-bootstrap';
import '../styles/signup.scss';
import Header from "../components/header";
import Step1 from "../components/signup/step1";
import Step2 from "../components/signup/step2";
import Step3 from "../components/signup/step3";
import Step4 from "../components/signup/step4";
import LeftPanel from "../components/leftPanel";

export default function Home() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const [searchParams] = useSearchParams();

  const step: any = searchParams.get('step');

  useEffect(() => {
    if (step === null) {
      navigate('/signup?step=1')
    }
  }, [])

  return (
    <React.Fragment>
      <Header backMode={{ pageName: "Sign up", backUrl: "/welcome" }} />
      <Container>
        <div className="wrapper-left-right-panel">
          <LeftPanel img={"/assets/img/SideSlide-2.jpg"} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."}/>
          <div className="card-outer signup-wrapper-outer">
            <div className="card">

              {(step == 1) ? <Step1 /> : null}
              {(step == 2) ? <Step2 /> : null}
              {(step == 3) ? <Step3 /> : null}
              {(step == 4) ? <Step4 /> : null}

            </div>
          </div>
        </div>
      </Container>



    </React.Fragment>



  );
}