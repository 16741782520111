import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import '../../styles/components/cases/caseCard.scss'

interface incomingProps {
    data: any
}


export default function Case({ data }: incomingProps) {

    return (
        <div className="case-card">
            <Link to={`/case/${data?.incidentid}`} className="case-link">
                <div className={data?.statuscode == 1 ? "case-status pending" : data?.statuscode == 0 ? "case-status resolved" : "case-status cancelled"}>
                    {data['statuscode@OData.Community.Display.V1.FormattedValue']}
                </div>
                <div className="d-flex justify-content-between case-card-inner pt-3 pb-2">
                    <div>
                        <h4>Case#{data?.xql_casenumber}</h4>
                    </div>
                    <div className="created-date">
                        <p>Created: {moment(data?.createdon).format('DD/MM/YY')}</p>
                    </div>
                </div>
                <div className="d-flex justify-content-between case-card-inner">
                    <div className="pe-0">
                        <p>{data?.description}</p>
                    </div>

                    {/* <div className="whithin-sla">
                        Within SLA
                    </div> */}
                </div>
            </Link>
        </div>
    )
}