import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import NoCases from "../components/cases/noCases";
import CasesList from "../components/cases/casesList";
import ConnectionDetails from "../components/connection/connectionDetails";

export default function Connected() {
    const [cases,setCases] = useState<any>([{},{}]);
    return (
        <React.Fragment>
            <Header />
            <Container>
                <ConnectionDetails data={{}}/>
            </Container>
        </React.Fragment>
    )
}