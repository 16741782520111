import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../../_config'

export interface CountryDirectoryReducer {
  popularCountries: any;
  popularCities: any;
}

const initialState: CountryDirectoryReducer = {
  popularCountries: null,
  popularCities: null,
};

const countryDirectoryReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COUNTRY_DIRECTORY.POPULAR_COUNTRIES:
      return {
        ...state,
        popularCountries: action.payload,
      };
    case ACTIONS.COUNTRY_DIRECTORY.POPULAR_CITIES:
      return {
        ...state,
        popularCities: action.payload,
      };
    default:
      return state;
  }
};

export default countryDirectoryReducer;
