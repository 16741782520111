import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../../common/appApiCallHook';
import {
    BlankReq, getCityAlertType, getCountryInfoType,
} from '../../../interfaces/ApiReqRes'


export function useCommonApi() {

    const callApi = useApiCall();
        const post = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.POST, data, onSuccess, onError);
        }

        const get = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET, data, onSuccess, onError);
        }

        const getCountries = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_COUNTRIES, data, onSuccess, onError);
        }

        const getCities = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_CITIES, data, onSuccess, onError);
        }

        const retrieveRelationShip = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.RETRIEVE_RELATIONSHIP, data, onSuccess, onError);
        }

        const retrieveGender = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.RETRIEVE_GENDER, data, onSuccess, onError);
        }

        const getGeoService = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_GEO_SERVICE, data, onSuccess, onError);
        }
       
        const retrieveLodgeType = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.RETRIEVE_LODGE_TYPE, data, onSuccess, onError);
        }
       
        const retrieveTravelReason = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.RETRIEVE_TRAVEL_REASON, data, onSuccess, onError);
        }
       
        const getCityAlert = (data: getCityAlertType, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_CITY_ALERT, data, onSuccess, onError);
        }
       
        const getCityInfo = (data: getCityAlertType, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.CITY_INFO, data, onSuccess, onError);
        }
       
        const getCountryInfo = (data: getCountryInfoType, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.COUNTRY_INFO, data, onSuccess, onError);
        }

    return {
        post: post,
        get: get,
        getCountries: getCountries,
        getCities: getCities,
        retrieveRelationShip: retrieveRelationShip,
        retrieveGender: retrieveGender,
        getGeoService: getGeoService,
        retrieveLodgeType: retrieveLodgeType,
        retrieveTravelReason: retrieveTravelReason,
        getCityAlert: getCityAlert,
        getCityInfo: getCityInfo,
        getCountryInfo: getCountryInfo,
    }
}