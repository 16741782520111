import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import '../../styles/components/connected/connectionCard.scss';

interface incomingProps {
    data:any
}


export default function Connection({data}:incomingProps) {

    const [_data, set_data] = useState<any>({
        id: 'CONUSER',
        name:'Van Zyl',
        city:'Leslie',
        img:'https://img.freepik.com/free-photo/beautiful-scenery-road-forest-with-lot-colorful-autumn-trees_181624-30942.jpg?w=600'
    })

    return (
        <div className="connection-card">
            <Link to={`/connected/${_data.id}`} className="connection-link"> 
            <Row>
                <Col sm={2}><img src={_data.img} className="connection-iamge"/></Col>
                <Col sm={8} className="content">
                    <h4>{_data.name} {_data.city}</h4>
                    <p><img src="/assets/img/bell.svg" className="notification"/> Connected</p>
                </Col>
                <Col sm={2} className="go-to-details"><img src="/assets/img/Vector.svg" className="connection-details"/></Col>
            </Row>
            </Link>
        </div>
    )
}