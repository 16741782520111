import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { URLS } from './_config';

import PrivateRoutes from './_config/privateRoutes';
import OpenRoutes from './_config/openRoutes';

import Login from './pages/login';
import SignUp from './pages/signup';
import Welcome from './pages/welcome';
import Dashboard from './pages/dashboard';
import Travels from './pages/travels';
import NewTravel from './pages/newTravel';
import ForgotPassword from './pages/forgotPassword';
import RegistrationSuccessful from './pages/registrationSuccessful';
import TravelCard from './components/travel/travelCard';
import Cases from './pages/cases';
import CaseDetails from './pages/caseDetails';
import Advisory from './pages/advisory';
import Notifications from './pages/notifications';
import NotificationDetails from './pages/notificationDetails';
import Profile from './pages/profile';
import Connect from './pages/connect';
import Connected from './pages/connected';
import ConnectRequest from './pages/connectRequest';
import ConnectedProfile from './pages/connectedProfile';


// css ----------
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss';
import PrivacyPolicy from './pages/privacyPolicy';
import TravelCity from './pages/travelCity';
import TravelCityInfo from './pages/travelCityInfo';
import ProfileDetails from './pages/update/profileDetails';
import ProfileSetting from './pages/profileSetting';
import KinDetails from './pages/update/kinDetails';
import LocationSettings from './pages/update/locationSettings';
import CountryDirectory from './pages/countryDirectory';
import PopularCountries from './pages/popularCountries';
import PopularCities from './pages/popularCities';
import CountryInfo from './pages/countryInfo';
import CityInfo from './pages/cityInfo';
import CreateCase from './pages/createCase';


function App() {
	return (
		<Router>
			<Routes>
				<Route element={<OpenRoutes />} >
					<Route path={URLS.WELCOME} element={<Welcome />} />
					<Route path={URLS.LOGIN} element={<Login />} />
					<Route path={URLS.SIGNUP} element={<SignUp />} />
					<Route path={URLS.PRIVACY_POLICY} element={<PrivacyPolicy />} />
					<Route path={URLS.FORGOT_PASSWORD} element={<ForgotPassword />} />
					<Route path={URLS.REGISTRATION_SUCCESSFUL} element={<RegistrationSuccessful />} />
					{/* bottom route will be in Private route */}
				</Route>


				{/* <Route path={URLS.SUCCESS_PAGE} element={<SuccessPage />} /> */}
				<Route element={<PrivateRoutes />} >
					<Route path={URLS.HOME} element={<Dashboard />} />
					<Route path={URLS.NEW_TRAVEL} element={<NewTravel />} />
					<Route path={URLS.TRAVELS} element={<Travels />} />
					<Route path={URLS.TRAVEL_DETAILS} element={<TravelCard />} />
					<Route path={URLS.TRAVEL_CITY} element={<TravelCity />} />
					<Route path={URLS.TRAVEL_CITY_INFO} element={<TravelCityInfo />} />
					<Route path={URLS.CASES} element={<Cases />} />
					<Route path={URLS.CASE_DETAILS} element={<CaseDetails />} />
					<Route path={URLS.CREATE_CASE} element={<CreateCase />} />
					<Route path={URLS.ADVISORY} element={<Advisory />} />
					<Route path={URLS.NOTIFICATIONS} element={<Notifications />} />
					<Route path={URLS.NOTIFICATION_DETAILS} element={<NotificationDetails />} />
					<Route path={URLS.PROFILE} element={<Profile />} />
					<Route path={URLS.PROFILE_SETTINGS} element={<ProfileSetting />} />
					<Route path={URLS.PROFILE_DETAILS} element={<ProfileDetails />} />
					<Route path={URLS.KIN_DETAILS} element={<KinDetails />} />
					<Route path={URLS.LOCATION_SETTINGS} element={<LocationSettings />} />
					<Route path={URLS.COUNTRY_DIRECTORY} element={<CountryDirectory />} />
					<Route path={URLS.PUPOLAR_COUTRIES} element={<PopularCountries />} />
					<Route path={URLS.POPULAR_CITIES} element={<PopularCities />} />
					<Route path={URLS.COUNTRY_INFO} element={<CountryInfo />} />
					<Route path={URLS.CITY_INFO} element={<CityInfo />} />
					<Route path={URLS.CONNECT} element={<Connect />} />
					<Route path={URLS.CONNECTED} element={<Connected />} />
					<Route path={URLS.CONNECT_REQUEST} element={<ConnectRequest />} />
					<Route path={URLS.CONNECTED_PROFILE} element={<ConnectedProfile />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;