import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';

export default function LocationSettings() {

    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()
    const user: any = useUserSelector()

    const [geoService, setGeoService] = useState<any>(null)
    const [shareLocationToggle, setShareLocationToggle] = useState<boolean>(false)
    const [longitude, setLongitude] = useState<any>(null)
    const [latitude, setLatitude] = useState<any>(null)
    const [profileId, setProfileId] = useState<any>(null)

    let ProfileId: any = sessionStorage.getItem("ProfileId")

    const getGeoService = () => {
        showLoader()
        commonApi.getGeoService({},
            (message: string, resp: any) => {
                hideLoader()
                setGeoService(resp)
            }, (message: string) => {
                hideLoader()
                // console.log(message);
            })
    }

    const onSubmit = (data: any) => {
        let params = {
            "EntityName": "GeoServices",
            "ContactId": user?.contactid,
            "Longitude": shareLocationToggle ? `${longitude}` : "",
            "Latitude": shareLocationToggle ? `${latitude}` : "",
            "ShareLocation": shareLocationToggle
        }
        console.log({ data })
        showLoader();
        userApi.registerStepFour(params, (message: string, resp: any) => {
            console.log({ resp })
            hideLoader();
            toast.success(message)
            navigate('/profile-settings')
            sessionStorage.removeItem("ProfileId")
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    useEffect(() => {
        getGeoService()
    }, [])

    useEffect(() => {
        if (shareLocationToggle) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitude(position.coords.latitude)
                setLongitude(position.coords.longitude)
            });
        }

    }, [shareLocationToggle])


    useEffect(() => {
        if (ProfileId == 'undefined') {
            setProfileId(null)
        } else {
            setProfileId(JSON.parse(ProfileId))
        }
    }, [ProfileId])

    return (
        <React.Fragment>
            <Header backMode={{ pageName: "Personal Details", backUrl: "/profile-settings" }} />
            <Container>
                <div className="card-outer signup-wrapper-outer">
                    <div className="card">
                        <h1 className="text-center">Location Settings</h1>
                        <h2>How would you like to register your services?</h2>
                        <div className="d-flex justify-content-between">
                            <h3>Use geo-location services:</h3>
                            <div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => setShareLocationToggle(!shareLocationToggle)}></input>
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>{geoService ? geoService[0]["xql_geoservicesinformation"] : null}</p>
                        </div>
                        <div className="mt-4 d-flex gap-2 login-btns">
                            <Button variant='success' onClick={onSubmit}>Save</Button>
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}