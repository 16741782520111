import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/header";
import LeftPanel from "../components/leftPanel";
import * as CountryList from 'country-list-with-dial-code-and-flag';
import { useNavigate } from 'react-router-dom';
import { useCasesApi } from '../_common/hooks/api/casesApiHook';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';

const CreateCase = () => {

  const navigate = useNavigate()
  const caseApi = useCasesApi()
  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader()
  const user: any = useUserSelector()


  const { register, handleSubmit, control, formState: { errors }, watch, reset } = useForm({ mode: "onChange" });
  const documents = watch('documents');
  const country = watch('CountryId');

  const [files, setFiles] = useState<any>([]);
  const [casesTypes, setCasesTypes] = useState<any>(null)
  const [countries, setCountries] = useState<any>(null)
  const [cities, setCities] = useState<any>(null)
  const [dialCode, setDialCode] = useState<any>(null)
  const [aloneToggle, setAloneToggle] = useState<boolean>(false)
  const [locationToggle, setLocationToggle] = useState<boolean>(false)
  const [affectedToggle, setAffectedToggle] = useState<boolean>(true)
  const [relationship, setRelationship] = useState<any>(null)
  const [caseMesssage, setCaseMessage] = useState<boolean>(false);

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  useEffect(() => {
    let _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
        checkIsSelected(file.name).then(() => {
          if (file.size <= 2 * 1024 * 1024) {
            toBase64(file).then((b64: any) => {
              _files.push({
                "FileName": file.name,
                // "FileExtention": file.name.split('.').pop(),
                // "DocumentTypeId": docType,
                "FileContent": b64.split(',')[1]
              });
              // if (_files.length == documents?.length) {
              console.log('files=>', _files);
              setFiles(_files)
              // }
            })
          } else {
            toast.error("File size should be less 2MB")
          }
        }).catch(() => { console.log('already selected') })
      }
    } else {
      console.log('files=>', _files);
      setFiles(_files)
    }
    // reset({
    //   documents: null
    // })
  }, [documents])

  const checkIsSelected = (name: string) => {
    // console.log('checking.................', files)
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject()
        }
      });
      resolve()
    })
  }

  const getCasesTypes = () => {
    caseApi.getCasesTypes({},
      (message: string, resp: any) => {
        // console.log({ resp })
        if (resp.length > 0) {
          setCasesTypes(resp.map((data: any) => {
            data.label = data.xql_name;
            data.value = data.xql_caseclassificationid;
            return data;
          }))
        }
      }, (message: string) => {
        console.log(message);
      })
  }

  const getCountries = () => {
    commonApi.getCountries({},
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setCountries(resp.map((data: any) => {
            data.label = data.xql_name;
            data.value = data.xql_countryid;
            return data;
          }))
        }
      }, (message: string) => {
        console.log(message);
      })
  }

  const getCities = (id: any = country?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCities({
        CountryId: id
      }, (message: string, resp: any) => {
        if (resp.length > 0) {
          setCities(resp.map((data: any) => {
            data.label = data.xql_name;
            data.value = data.xql_cityid;
            return data;
          }))
        }
        setTimeout(() => {
          resolve()
        }, 300)
      }, (message: string) => {
        // console.log(message);
        reject()
      });
    })
  }

  const retrieveRelationShip = () => {
    commonApi.retrieveRelationShip({ "DropdownName": "xql_relationshiotype" },
      (message: string, resp: any) => {
        let options: any = [];
        if (resp.Options && resp.Options.length > 0) {
          resp.Options.map((item: any) => {
            item.value = item.Value
            item.label = item.Label.UserLocalizedLabel.Label
            options.push(item)
          })
          setRelationship(options)
        }
      }, (message: string) => {
        console.log(message);
      })
  }

  const getDialCode = (data: any) => {
    if (data.length > 0) {
      setDialCode(data.map((item: any) => {
        item.label = item.dial_code;
        item.value = item.dial_code;
        return item;
      }))
    }
  }

  const dialCodeOptionUI = (props: any) => {
    const { innerProps, innerRef, data } = props;
    return (
      <div ref={innerRef} {...innerProps} className="dial-code-option">
        {data.flag}
        <span>{data.dial_code}</span>
      </div>
    );
  }

  const onSubmit = (data: any) => {
    let params = {
      "EntityName": "Case",
      "ContactId": user?.contactid,
      "CaseTypeId": data.CaseTypeId.value,
      "Description": data.Description,
      "CountryId": data.CountryId.value,
      "CityId": data.CityId.value,
      "ShareLocation": locationToggle,
      "AreYouAlone": aloneToggle,
      "AreYouTheAffectedParty": affectedToggle,
      "IDNumber": "",
    }
    console.log({ data });
    showLoader();
    caseApi.createCase(params,
      (message: string, resp: any) => {
        console.log({ message, resp })
        hideLoader()
        if (affectedToggle) {
          submitStepTwo(data, resp.CaseId)
        }
        if (resp.IsAutoCase) {
          createCaseNotification(resp)
        }
        docUpload(resp.CaseId)
        // toast.success(message)
        reset({
          CaseTypeId: null,
          CountryId: null,
          CityId: null,
          Description: null,
          documents: null,
          IDNumber: null,
          FirstName: null,
          LastName: null,
          Email: null,
          DialCode: null,
          MobilePhone: null
        });
        setFiles([]);
        navigate(`/cases`)
      }, (message: string) => {
        console.log({ message })
        hideLoader()
        toast.error(message)
        // reset({
        //   CaseTypeId: null,
        //   CountryId: null,
        //   CityId: null,
        //   Description: null,
        //   documents: null,
        //   IDNumber: null,
        //   FirstName: null,
        //   LastName: null,
        //   Email: null,
        //   DialCode: null,
        //   MobilePhone: null
        // });
        // setFiles([]);
      })
  }

  const submitStepTwo = (data: any, caseId: any) => {
    let params = {
      "EntityName": "AffectedParty",
      "CaseId": caseId,
      "AreYouTheAffectedParty": affectedToggle,
      "IDNumber": data.IDNumber,
      "FirstName": data.FirstName,
      "LastName": data.LastName,
      "Email": data.Email,
      "DialCode": data.DialCode.value,
      "MobilePhone": data.MobilePhone
    }
    console.log("two==>", { data, caseId })

    caseApi.createCaseStepTwo(params,
      (message: string, resp: any) => {
        reset({
          CaseTypeId: null,
          CountryId: null,
          CityId: null,
          Description: null,
          documents: null,
          IDNumber: null,
          FirstName: null,
          LastName: null,
          Email: null,
          DialCode: null,
          MobilePhone: null
        });
      }, (message: string) => {
        // reset({
        //   CaseTypeId: null,
        //   CountryId: null,
        //   CityId: null,
        //   Description: null,
        //   documents: null,
        //   IDNumber: null,
        //   FirstName: null,
        //   LastName: null,
        //   Email: null,
        //   DialCode: null,
        //   MobilePhone: null
        // });
        console.log(message)
      })
  }

  const docUpload = (caseId: any) => {
    console.log("docUpload")
    let params = {
      "CaseId": caseId,
      "Images": files
    }
    if (files.length > 0) {
      caseApi.createCaseDocUpload(params,
        (message: string, resp: any) => { },
        (message: string) => { })
    }
  }

  const deleteLocal = (file: any) => {
    let _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file)
      }
    });

    setFiles(_files)
  }

  const createCaseNotification = (resp: any) => {
    toast(
      (t) => (
        <span>
          <div className='modal-tag-notification' style={{ background: 'black', fontSize: "12px", borderRadius: "0 5px 5px 0", color: "white", display: "inline-block", marginBottom: "10px", padding: "3px 6px" }}>{resp?.CaseStatus}</div>
          <br />
          <b >Your case with case number #{resp?.CaseNumber} has be automatically closed.</b>
          <br />
          <br />
          <p>Consular Service are of an advisory and <b>non-financial</b> nature and are provided free of charge, unless otherwise specified.</p>
          <br />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-success w-100"
              onClick={() => { toast.dismiss(t.id); navigate(`/cases`) }}
            >
              Go back
            </button>
          </div>
        </span>
      ),
      { duration: 20000, className: 'withdraw-toast', position: "bottom-center" }
    );
  }

  useEffect(() => {
    getCasesTypes()
    getCountries()
    retrieveRelationShip()
    getDialCode(CountryList.getList())
  }, [])

  useEffect(() => {
    if (country) {
      getCities()
    }
  }, [country])

  return (
    <React.Fragment>
      <Header active={"cases"}/>
      <Container>
        <div className="wrapper-left-right-panel">
          <LeftPanel img={"/assets/img/SideSlide-2.jpg"} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."} />
          <div className="create-case-form w-100">
            <h2>Create case</h2>
            <Row>
              <Col sm={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Use my Current location</h3>
                  <div>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => setLocationToggle(!locationToggle)} />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col sm={6}>
                <div className="d-flex align-items-center justify-content-between align-items-center mb-3"><h3 className="mb-0">Are you alone?</h3>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultTwo" />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefaultTwo"></label>
                  </div>
                </div>
              </Col> */}
            </Row>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <Row>
                <Col sm={6}>
                  <Row>
                    <Col sm={12}>
                      <fieldset className={"input filled" + (errors?.CaseTypeId ? ' invalid' : '')}>
                        <legend>Case Type</legend>
                        <Controller
                          name={"CaseTypeId"}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (<Select
                              className={"select-box"}
                              options={casesTypes ? casesTypes : []}
                              placeholder={""}
                              onChange={onChange}
                              value={value}
                              defaultValue={value}
                            />)
                          }}
                        />
                      </fieldset>
                    </Col>
                    <Col sm={12}>
                      <fieldset className={"input filled" + (errors?.CountryId ? ' invalid' : '')}>
                        <legend>Country (you’re travelling/residing into)</legend>
                        <Controller
                          name={"CountryId"}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (<Select
                              className={"select-box"}
                              options={countries ? countries : []}
                              placeholder={""}
                              onChange={onChange}
                              value={value}
                              defaultValue={value}
                            />)
                          }}
                        />
                      </fieldset>
                    </Col>
                    <Col sm={12}>
                      <fieldset className={"input filled" + (errors?.CityId ? ' invalid' : '')}>
                        <legend>City</legend>
                        <Controller
                          name={"CityId"}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (<Select
                              className={"select-box"}
                              options={cities ? cities : []}
                              placeholder={""}
                              onChange={onChange}
                              value={value}
                              defaultValue={value}
                            />)
                          }}
                        />
                      </fieldset>
                    </Col>
                  </Row>

                </Col>

                <Col sm={6}>
                  <fieldset className={"input filled" + (errors?.Description ? ' invalid' : '')}>
                    <legend>Description</legend>
                    <textarea rows={7} {...register("Description", { required: true, maxLength: 80 })} />
                  </fieldset>
                </Col>
              </Row>

              <Row className='mt-2
              '>
                <Col sm={12}>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3>I'm not the affected party</h3>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultTwo" onChange={() => setAffectedToggle(!affectedToggle)} checked={affectedToggle} />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefaultTwo"></label>
                    </div>
                  </div>
                </Col>
              </Row>

              {affectedToggle ?
                <React.Fragment>
                  <Row>
                    <Col sm={6}>
                      <fieldset className={"input filled" + (errors?.IDNumber ? ' invalid' : '')}>
                        <legend>ID Number</legend>
                        <input {...register("IDNumber", { required: affectedToggle, maxLength: 80 })} />
                      </fieldset>
                    </Col>
                    <Col sm={6}>
                      <fieldset className={"input filled" + (errors?.FirstName ? ' invalid' : '')}>
                        <legend>First Name</legend>
                        <input {...register("FirstName", { required: affectedToggle, maxLength: 80 })} />
                      </fieldset>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <fieldset className={"input filled" + (errors?.LastName ? ' invalid' : '')}>
                        <legend>Last Name</legend>
                        <input {...register("LastName", { required: affectedToggle, maxLength: 80 })} />
                      </fieldset>
                    </Col>
                    <Col sm={6}>
                      <fieldset className={"input filled" + (errors?.Email ? ' invalid' : '')}>
                        <legend>Email</legend>
                        <input {...register("Email", { required: affectedToggle, maxLength: 80 })} />
                      </fieldset>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <fieldset className={"input filled" + (errors?.RelationshipType ? ' invalid' : '')}>
                        <legend>Relationship</legend>
                        <Controller
                          name={"RelationshipType"}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (<Select
                              className={"select-box"}
                              options={relationship ? relationship : []}
                              placeholder={""}
                              onChange={onChange}
                              value={value}
                              defaultValue={value}
                            />)
                          }}
                        />
                      </fieldset>
                    </Col>
                    <Col sm={3}>
                      <fieldset className={"input filled" + (errors?.DialCode ? ' invalid' : '')}>
                        <legend>Code</legend>
                        <Controller
                          name={"DialCode"}
                          control={control}
                          rules={{ required: affectedToggle }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (<Select
                              components={{ Option: dialCodeOptionUI }}
                              className={"select-box"}
                              options={dialCode ? dialCode : []}
                              placeholder={""}
                              onChange={onChange}
                              value={value}
                              defaultValue={value}
                            />)
                          }}
                        />
                      </fieldset>
                    </Col>
                    <Col sm={5}>
                      <fieldset className={"input filled" + (errors?.MobilePhone ? ' invalid' : '')}>
                        <legend>Phone Number</legend>
                        <input type="text" className="" {...register("MobilePhone", { required: affectedToggle, maxLength: 80 })}></input>
                      </fieldset>
                    </Col>
                  </Row>
                </React.Fragment>
                :
                null
              }

              <Row>
                <Col sm={12}>
                  <div className="file-attchment mt-2">
                    <label>Attachments</label>
                    <input type="file" {...register('documents', { required: false })} />
                    <h6>Upload your attachment</h6>
                  </div>
                </Col>
              </Row>

              <Row>
                {files.map((file: any, i: any) => (
                  <Col sm={6} className='mt-2' key={i}>
                    <div className="">
                      <FaTimes style={{ color: 'red' }} className='me-2' onClick={() => { deleteLocal(file) }} />
                      <span>{file.FileName}</span>
                    </div>
                  </Col>
                ))}
              </Row>

              <Row className="my-4">
                <Col sm={6}>
                  <button type="submit" className="btn btn-success w-100">Create case</button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default CreateCase